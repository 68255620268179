import {
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import CustomButton from "../../../components/CustomButton";
import CustomModal from "../../../components/CustomModal/CustomModal";
import CustomTextField from "../../../components/CustomTextField";
import { AppColors } from "../../../util/AppColors";
import { getKey } from "../../../util/function";
import BranchManagementMain from "./BranchManagementMain";

const tableHeaders = ["name", "emailId", "role", "status", "edit"];

const tableNameExpan = {
  name: "Name",
  emailId: "Email ID",
  role: "Role",
  status: "Status",
  edit: "",
};

const list = [
  {
    name: "Name1",
    emailId: "name1@yopmail.com",
    roles: ["Admin", "Front Tesk"],
    status: [
      { name: "Admin", active: false },
      { name: "Front Tesk", value: true },
    ],
    edit: <img src={require("../../../assets/images/png/edit2.png")} />,
  },
  {
    name: "Name2",
    emailId: "name2@yopmail.com",
    roles: ["Pharmacy", "Front Tesk"],
    status: [
      { name: "Pharmacy", active: true },
      { name: "Front Tesk", value: true },
    ],
    edit: <img src={require("../../../assets/images/png/edit2.png")} />,
  },
];

function AccessAndRoles() {
  const [editModVsble, setEditModVsble] = useState(false);

  const onCloseEditMod = () => {
    setEditModVsble(false);
  };

  return (
    <BranchManagementMain active="accessAndRoles">
      <Container maxWidth="xl" className="mv20">
        <Table sx={{ minWidth: 200 }} aria-labelledby="tableTitle" stickyHeader>
          <TableHead>
            <TableRow>
              {tableHeaders?.map((hd) => (
                <TableCell
                  key={getKey()}
                  sortDirection={false}
                  className={`table-header-text capitalize `}
                  style={{
                    background: AppColors.tableGrey,
                    color: AppColors.white,
                  }}
                >
                  {tableNameExpan?.[hd]}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.map((li) => (
              <TableRow key={getKey()}>
                {tableHeaders?.map((hdr) => (
                  <TableCell>
                    {hdr === "role"
                      ? "role"
                      : hdr === "status"
                      ? "status"
                      : "hello"}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Container>

      <CustomModal
        open={editModVsble}
        onClose={onCloseEditMod}
        header="Edit"
        rightModal
        modalWidth={30}
      >
        <Grid container spacing={2} className="ph20">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <CustomTextField
              label="Name"
              fullWidth
              value="Namm"
              labelTop
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <CustomTextField
              label="Email"
              fullWidth
              value="Email"
              labelTop
              disabled
            />
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ marginLeft: "300px", marginTop: "30px" }}
        >
          <div style={{ width: "20px" }}>
            <CustomButton text="Save" smallBtn={true} />
          </div>
        </Grid>
      </CustomModal>
    </BranchManagementMain>
  );
}

export default AccessAndRoles;
