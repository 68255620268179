export const breedList = [
  { id: 1, name: "Affenpinscher", value: "Affenpinscher" },
];

export const genderList = [
  { name: "Female", value: "female" },
  { name: "Male", value: "male" },
];

export const yesNoList = [
  { name: "No", value: "false" },
  { name: "Yes", value: "true" },
];

export const yesNoValueList = [
  { name: "No", value: "No" },
  { name: "Yes", value: "Yes" },
];

export const typeAppointemnt = [
  { name: "Phone", value: "Phone" },
  { name: "Walk-in", value: "Walk-in" },
];

export const typeService = [
  { name: "Consultation", value: "Consultation" },
  { name: "Grooming", value: "Grooming" },
  { name: "Pet Boarding", value: "Pet Boarding" },
];

export const serviceType = [
  { name: "Consultation", value: "Consultation" },
  { name: "Emergency", value: "Emergency" },
  { name: "Grooming", value: "Grooming" },
  { name: "Others", value: "Others" },
  { name: "Surgery", value: "Surgery" },
  { name: "Vaccination", value: "Vaccination" },
];

export const typeList = [
  { name: "Physical", value: "Physical" },
  { name: "Virtual", value: "Virtual" },
];

export const petAppntTypeList = [
  { name: "Inpatient", value: "Inpatient" },
  { name: "Outpatient", value: "Outpatient" },
];

export const typeListNew = [{ name: "Physical", value: "Physical" }];

export const clinicRolesList = [
  { name: "Admin", value: "Admin" },
  { name: "Billing", value: "Billing" },
  { name: "Pharmacy Technician", value: "Pharmacy Technician" },
  { name: "Front Desk", value: "Front Desk" },
  { name: "Animal Caretaker/Attendant", value: "Animal Caretaker/Attendant" },
  { name: "Groomer", value: "Groomer" },
  { name: "Laboratory Technician", value: "Laboratory Technician" },
  {
    name: "Practice Manager/Administrator",
    value: "Practice Manager/Administrator",
  },
  { name: "Radiology Technician", value: "Radiology Technician" },
  { name: "Receptionist", value: "Receptionist" },
  { name: "Veterinary Technician/Nurse", value: "Veterinary Technician/Nurse" },
  { name: "Vetinary Assistant", value: "Vetinary Assistant" },
];

export const accessList = [
  { name: "Admin", value: "AD" },
  { name: "Billing", value: "BL" },
  { name: "Pharmacy Technician", value: "PH" },
  { name: "Front Desk", value: "RE" },
];

export const petTypeList = [{ name: "Dog", value: "dog" }];

export const salutationList = [
  { name: "Dr.", value: "Dr" },
  { name: "Mr.", value: "Mr" },
  { name: "Mrs.", value: "Mrs" },
  { name: "Ms.", value: "Ms" },
];

export const specialtyList = [
  { name: "Anesthesiology", value: "Anesthesiology" },
  { name: "Behavioral Medicine", value: "Behavioral Medicine" },
  { name: "Cardiology", value: "Cardiology" },
  { name: "Dentistry", value: "Dentistry" },
  { name: "Dermatology", value: "Dermatology" },
  { name: "Emergency and Critical Care", value: "Emergency and Critical Care" },
  {
    name: "Endocrinology and Metabolism",
    value: "Endocrinology and Metabolism",
  },
  { name: "Gastroenterology", value: "Gastroenterology" },
  { name: "General Medicine", value: "General Medicine" },
  { name: "Hematology/Immunology", value: "Hematology/Immunology" },
  { name: "Hepatology", value: "Hepatology" },
  { name: "Infectious Disease", value: "Infectious Disease" },
  { name: "Internal Medicine", value: "Internal Medicine" },
  { name: "Musculoskeletal", value: "Musculoskeletal" },
  { name: "Nephrology/Urology", value: "Nephrology/Urology" },
  { name: "Neurology", value: "Neurology" },
  { name: "Nutrition", value: "Nutrition" },
  { name: "Oncology", value: "Oncology" },
  { name: "Ophthalmology", value: "Ophthalmology" },
  { name: "Radiology", value: "Radiology" },
  { name: "Respiratory", value: "Respiratory" },
  { name: "Surgery", value: "Surgery" },
  { name: "Theriogenology", value: "Theriogenology" },
  { name: "Toxicology", value: "Toxicology" },
  { name: "Zoological Medicine", value: "Zoological Medicine" },
];

export const paymentSearchTypeList = [
  { name: "Pet Name", value: "pet-name" },
  { name: "Vet Name", value: "vet-name" },
];

export const dateFilterList = [
  { name: "Today", value: "today" },
  { name: "This Week", value: "this" },
];

export const medicalHistorySearchTypelist = [
  { name: "Condition", value: "condition" },
  { name: "Date", value: "date_range" },
];

export const dateFilterTodayLastWeek = [
  { name: "Today", value: "today" },
  { name: "Last week", value: "last" },
];

export const vetDropList = [{ name: "Vet Name", value: "vet-name" }];

export const appointDropList = [
  { name: "Pet Name", value: "pet-name" },
  { name: "Vet Name", value: "vet-name" },
  { name: "Order Id", value: "order-id" },
];

export const appointDropListProduct = [
  { name: "Order Id", value: "order-id" },
  { name: "Product Name", value: "product-name" },
  { name: "Vet Name", value: "vet-name" },
];
export const locationDropList = [
  { name: "Chennai", value: "chennai" },
  { name: "Delhi", value: "delhi" },
  { name: "Hydrabad", value: "hydrabad" },
  { name: "Jaipur", value: "jaipur" },
  { name: "Kolkata", value: "kolkata" },
  { name: "Mumbai", value: "mumbai" },
  { name: "Noida", value: "noida" },
  { name: "Raipur", value: "raipur" },
  { name: "Tamil Nadu", value: "tamil nadu" },
];

export const NeuteredList = [
  { name: "Intact", value: "Intact" },
  { name: "Neutered", value: "Neutered" },
  { name: "Spayed", value: "Spayed" },
];

export const PaymentTypeList = [
  { name: "Cash", value: "cash" },
  { name: "Card", value: "card" },
  { name: "Net Banking", value: "netBanking" },
  { name: "Upi", value: "upi" },
];

export const paymentFilters = [
  { name: "All", value: "all" },
  { name: "Paid", value: "Paid" },
  // { name: "Partially Paid", value: "Partially" },
  { name: "Unpaid", value: "Unpaid" },
];

export const payReportFilter = [
  { name: "All", value: "all" },
  { name: "Paid", value: "paid" },
  { name: "Unpaid", value: "unpaid" },
];

export const taskCategory = [
  { name: "Deworming", value: "Deworming" },
  { name: "Diagnostics", value: "Diagnostics" },
  { name: "Flea/Tick Treatment", value: "Flea & Tick Treatment" },
  { name: "Medication", value: "Medication" },
  { name: "Others", value: "Others" },
  { name: "Vaccination", value: "Vaccines" },
];

export const yesNoBoolList = [
  { name: "No", value: false },
  { name: "Yes", value: true },
];

export const productCategoryList = [
  { name: "Deworming", value: "Deworming" },
  { name: "Diet", value: "Diet" },
  { name: "Flea & Tick Treatment", value: "Flea & Tick Treatment" },
  { name: "Fluid Therapy", value: "Fluid Therapy" },
  { name: "General Consumables", value: "General Consumables" },
  { name: "Grooming & Hygiene Care", value: "Grooming & Hygiene Care" },
  { name: "Lab Supplies", value: "Lab Supplies" },
  { name: "Medical Supplies", value: "Medical Supplies" },
  { name: "Medication", value: "Medication" },
  { name: "Mortuary", value: "Mortuary" },
  { name: "Nutrition and Supplements", value: "Nutrition and Supplements" },
  { name: "OTC Products", value: "OTC Products" },
  { name: "Other Parasite Treatment", value: "Other Parasite Treatment" },
  { name: "Pet Supplies", value: "Pet Supplies" },
  { name: "Surgical Supplies", value: "Surgical Supplies" },
  { name: "Vaccines", value: "Vaccines" },
];

export const serviceCategoryList = [
  { name: "Boarding", value: "Boarding" },
  { name: "Consulation & General care", value: "Consulation & General care" },
  { name: "Diagnostics", value: "Diagnostics" },
  { name: "In-patient", value: "In-patient" },
  { name: "Procedure", value: "Procedure" },
  { name: "Surgery", value: "Surgery" },
];

export const productMeasurementUnit = [
  { name: "Ampoule", value: "Ampoule" },
  { name: "Blister", value: "Blister" },
  { name: "Bottle", value: "Bottle" },
  { name: "Box", value: "Box" },
  { name: "Box (10 strips)", value: "Box (10 strips)" },
  { name: "Can", value: "Can" },
  { name: "Chewable Tablet", value: "Chewable Tablet" },
  { name: "Container", value: "Container" },
  { name: "Jar", value: "Jar" },
  { name: "Pack", value: "Pack" },
  { name: "Piece (N)", value: "Piece (N)" },
  { name: "Pipette", value: "Pipette" },
  { name: "Sachet", value: "Sachet" },
  { name: "Strip", value: "Strip" },
  { name: "Syringe", value: "Syringe" },
  { name: "Tube", value: "Tube" },
  { name: "Vial", value: "Vial" },
];

export const productSubMeasurementUnit = [
  { name: "20g ", value: "20g " },
  { name: "1 mL ampoule", value: "1 mL ampoule" },
  { name: "5 ml", value: "5 ml" },
  { name: "5 mL vial", value: "5 mL vial" },
  { name: "6 ml vial", value: "6 ml vial" },
  { name: "Ampoulea", value: "Ampoulea" },
  { name: "Caplet", value: "Caplet" },
  { name: "Capsule", value: "Capsule" },
  { name: "Dose", value: "Dose" },
  { name: "Gram", value: "Gram" },
  { name: "Grams", value: "Grams" },
  { name: "Gram (gm)", value: "Gram (gm)" },
  { name: "Grams (g)", value: "Grams (g)" },
  { name: "ml", value: "ml" },
  { name: "mL", value: "mL" },
  { name: "Milliliter (ml)", value: "Milliliter (ml)" },
  { name: "Milliliters", value: "Milliliters" },
  { name: "Milliliters (ml)", value: "Milliliters (ml)" },
  { name: "mL (Milliliters)", value: "mL (Milliliters)" },
  { name: "Patch", value: "Patch" },
  { name: "Prefilled syringe", value: "Prefilled syringe" },
  { name: "Single Tablet", value: "Single Tablet" },
  { name: "Soft Chew", value: "Soft Chew" },
  { name: "Softgel Capsule", value: "Softgel Capsule" },
  { name: "Strip of tablets", value: "Strip of tablets" },
  { name: "Tablet", value: "Tablet" },
  { name: "Vial", value: "Vial" },
];

export const natureList = [
  { name: "Capsule", value: "Capsule" },
  { name: "Eye Drops", value: "Eye Drops" },
  { name: "Injection", value: "Injection" },
  { name: "Liquid Suspension", value: "Liquid Suspension" },
  { name: "Oral solution", value: "Oral solution" },
  { name: "Paste", value: "Paste" },
  { name: "Powder", value: "Powder" },
  { name: "Syrup", value: "Syrup" },
  { name: "Tablet", value: "Tablet" },
];
