import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import NavigateBeforeOutlinedIcon from "@mui/icons-material/NavigateBeforeOutlined";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import {
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import { groupBy } from "lodash";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CustomTextField from "../../../components/CustomTextField";
import Select from "../../../components/Select/Select";
import TopBar from "../../../components/TopBar/TopBar";
import {
  getAppointsmentsByClinic,
  getVetAppointmentsByIdAndFilter,
} from "../../../redux/reducers/clinicSlice";
import { storeApntmntScreenDetails } from "../../../redux/reducers/mixedSlice";
import { getClinicVets } from "../../../redux/reducers/vetSlice";
import { AppColors } from "../../../util/AppColors";
import {
  createTimeSlots,
  getDateList,
  getHourRanges,
  getKey,
  isTimeBetween,
} from "../../../util/function";
import AppointmentApprove from "./AppointmentApprove";

const Appointments = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(
    location?.state?.selectedTab ?? "calendar"
  );
  const [selectedType, setSelectedType] = useState("today");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [timeList, setTimeList] = useState([]);
  const [dateList, setDateList] = useState([]);
  const [reqApnts, setReqApnts] = useState(null);
  const [vetDropList, setVetDropList] = useState([]);
  const [selectedVet, setSelectedVet] = useState("all");
  const vetAppointments = useSelector(
    (state) => state?.clinic?.vetAppointments
  );
  const appointments = useSelector((state) => state.clinic.appointments);
  const appointemntApproveList = useSelector(
    (state) => state?.clinic?.appointmentList
  );
  const clinicVets = useSelector((state) => state?.vet?.vets);
  const vetList = appointments?.doctors;
  const appointmentList = appointments?.data;
  const timeRef = useRef(null);

  useEffect(() => {
    getDateAndTimeLists();
    dispatch(getClinicVets());
  }, []);

  useEffect(() => {
    if (timeRef?.current) {
      timeRef?.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [timeList]);

  useEffect(() => {
    const reqVetList = clinicVets
      ?.filter((vl) => {
        if (vl?.userType?.find((ut) => ut?.name === "ROLE_DOCTOR")) {
          return vl;
        }
      })
      ?.map((vet) => {
        return { name: vet?.name, value: vet?.doctorId, id: vet?.doctorId };
      });
    reqVetList?.unshift({ name: "All", value: "all" });
    setVetDropList(reqVetList);
  }, [clinicVets]);

  useEffect(() => {
    if (selectedVet === "all")
      dispatch(
        getAppointsmentsByClinic(
          `?type=${selectedType}${
            selectedType === "date"
              ? `&date=${moment(selectedDate).format("YYYY-MM-DD")}`
              : ""
          }`
        )
      );
    else {
      dispatch(
        getVetAppointmentsByIdAndFilter(
          `${selectedVet}?type=${selectedType}${
            selectedType === "date"
              ? `&date=${moment(selectedDate).format("YYYY-MM-DD")}`
              : ""
          }`
        )
      );
    }
    //for storing details to call socket
    dispatch(
      storeApntmntScreenDetails({
        selectedVet,
        selectedType,
        selectedDate,
      })
    );
  }, [selectedType, selectedDate]);

  useEffect(() => {
    getReqAppointments();
  }, [vetList, appointmentList, vetAppointments]);

  const handleChangeVet = (e) => {
    setSelectedVet(e?.target?.value);
    if (e?.target?.value === "all") {
      dispatch(
        getAppointsmentsByClinic(
          `?type=${selectedType}${
            selectedType === "date"
              ? `&date=${moment(selectedDate).format("YYYY-MM-DD")}`
              : ""
          }`
        )
      );
    } else {
      dispatch(
        getVetAppointmentsByIdAndFilter(
          `${e?.target?.value}?type=${selectedType}${
            selectedType === "date"
              ? `&date=${moment(selectedDate).format("YYYY-MM-DD")}`
              : ""
          }`
        )
      );
    }
  };

  const getReqAppointments = () => {
    const listToFilter =
      selectedVet === "all" ? appointmentList : vetAppointments;
    const reqList = [];
    timeList?.forEach((tm) => {
      listToFilter?.forEach((sl) => {
        const timeBet = isTimeBetween(sl?.appoimentTime, tm);
        if (timeBet) {
          if (sl?.serviceType === "Surgery") {
            const hrRange = getHourRanges(sl?.surgeryTimes);
            if (hrRange?.length > 0) {
              hrRange?.forEach((hr) => {
                reqList.push({ ...sl, time: hr });
              });
            }
          } else {
            reqList.push({ ...sl, time: `${tm}-${tm?.split(":")?.[0]}:59` });
          }
        }
      });
    });
    setReqApnts(groupBy(reqList, "time"));
  };

  const getDateAndTimeLists = async () => {
    const reqTimes = await createTimeSlots("00:00", "23:59", 60);
    setTimeList(reqTimes);
    const dates = await getDateList();
    setDateList(dates);
  };

  const handleDateChange = (value) => {
    const reqDate = new Date(selectedDate);
    reqDate.setDate(
      value === "add" ? reqDate.getDate() + 1 : reqDate.getDate() - 1
    );
    setSelectedDate(reqDate);
  };

  const checkSameDay = () => {
    return new Date().getDate() !== new Date(selectedDate).getDate();
  };

  const navDetailsPage = (det) => {
    navigate(`/clinic-pet-details`, {
      state: { appointment: { appoinment: det } },
    });
  };

  return (
    <>
      <TopBar>
        <Container maxWidth="xl">
          <Grid container spacing={0} className="flex-center">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container spacing={0}>
                <div className="flex-row">
                  <div
                    className="ph10pv3"
                    onClick={() => setSelectedTab("calendar")}
                  >
                    <div
                      className={`flex-center cursor ${
                        selectedTab === "calendar"
                          ? "pet-det-sel-tab"
                          : "pet-det-un-sel-tab"
                      }`}
                    >
                      Calendar
                    </div>
                  </div>
                  <div
                    className="ph10pv3"
                    onClick={() => setSelectedTab("requests")}
                  >
                    <div
                      className={`flex-center cursor ${
                        selectedTab === "requests"
                          ? "pet-det-sel-tab"
                          : "pet-det-un-sel-tab"
                      }`}
                    >
                      Requests
                      {appointemntApproveList?.length > 0 && (
                        <div className="red-dot request-btn-dot-pos" />
                      )}
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </TopBar>
      {selectedTab === "calendar" ? (
        <>
          <Grid container sx={{ padding: 2, alignItems: "center" }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Container maxWidth="xl">
                <Toolbar variant="regular">
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="flex-row">
                      <div className="top-row-cen-con w100Per">
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                          <CustomTextField
                            placeholder="Search"
                            name="name"
                            fullWidth
                            search
                          />
                        </Grid>

                        <div className="top-row-right-con w15Per topBar-select">
                          <Select
                            list={vetDropList}
                            value={selectedVet}
                            handleChange={handleChangeVet}
                            select
                          />
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Toolbar>
              </Container>
            </Grid>
          </Grid>
          <Container maxWidth="xl" className="ph40">
            <div className="back-white brd-r20">
              <Grid container className="mv10 ph20">
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="p10"
                >
                  <div className="flex-row">
                    <div className="flex-start">
                      <div className="flex-center">
                        <Typography
                          className={`font-bold fs14 cursor day-type-box ${
                            selectedType === "today" ? "black" : "gray2"
                          }`}
                          onClick={() => setSelectedType("today")}
                        >
                          Today
                        </Typography>
                      </div>
                    </div>
                    <div className="flex1-center">
                      <div className="flex-row">
                        <div className="flex-center">
                          <NavigateBeforeOutlinedIcon
                            className={`calendar-arrow ${
                              selectedType === "date" && checkSameDay()
                                ? "cursor"
                                : "no-cursor"
                            }`}
                            onClick={() => handleDateChange("minus")}
                          />
                          <Typography className="text-bold mh20">
                            {selectedType === "today"
                              ? moment(new Date()).format("DD MMM YYYY")
                              : selectedType === "date"
                              ? moment(new Date(selectedDate)).format(
                                  "DD MMM YYYY"
                                )
                              : `${moment(new Date()).format(
                                  "DD MMM"
                                )} - ${moment(
                                  new Date(
                                    new Date().setDate(new Date().getDate() + 6)
                                  )
                                ).format("DD MMM YYYY")}`}
                          </Typography>
                          <NavigateNextOutlinedIcon
                            className={`calendar-arrow ${
                              selectedType === "date" ? "cursor" : "no-cursor"
                            }`}
                            onClick={() => handleDateChange("add")}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex-end">
                      <Typography
                        className={`font-bold fs14 cursor day-type-box ${
                          selectedType === "date" ? "black" : "gray2"
                        }`}
                        onClick={() => setSelectedType("date")}
                      >
                        Day
                      </Typography>
                      <Typography
                        className={`font-bold fs14 cursor day-type-box ${
                          selectedType === "week" ? "black" : "gray2"
                        }`}
                        onClick={() => setSelectedType("week")}
                      >
                        Week
                      </Typography>
                    </div>
                  </div>
                </Grid>
                {selectedVet === "all" && (
                  <TableContainer sx={{ maxHeight: 380 }}>
                    <Table
                      sx={{ minWidth: 200 }}
                      aria-labelledby="tableTitle"
                      stickyHeader
                    >
                      <TableHead>
                        <TableRow>
                          {[{}, ...(vetList ?? [])]?.map((vet, i) => (
                            <TableCell
                              key={getKey()}
                              style={
                                i === 0
                                  ? {
                                      backgroundColor: AppColors.white,
                                      color: AppColors.black,
                                      textAlign: "left",
                                      position: "sticky",
                                      left: 0,
                                      zIndex: 105,
                                      border: "1px solid #adb8cc",
                                      height: 70,
                                    }
                                  : {
                                      backgroundColor: AppColors.white,
                                      color: AppColors.black,
                                      textAlign: "center",
                                      border: "1px solid #adb8cc",
                                      height: 70,
                                    }
                              }
                              sortDirection={false}
                              className={`table-header-text capitalize `}
                            >
                              {i === 0 ? (
                                <div style={{ width: 100 }}>
                                  {selectedType === "week" ? (
                                    <div className="flex-center">
                                      <CalendarMonthIcon className="gray2 w30h30" />
                                    </div>
                                  ) : (
                                    <div />
                                  )}
                                </div>
                              ) : (
                                <div
                                  style={{
                                    width: selectedType === "week" ? 150 : 600,
                                  }}
                                >
                                  <Typography className="text-bold capitalize fs14">
                                    {`Dr. ${vet?.name}`}
                                  </Typography>
                                </div>
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedType === "week" &&
                          dateList?.length > 0 &&
                          dateList?.map((dl, i) => {
                            return (
                              <TableRow tabIndex={-1}>
                                <TableCell
                                  key={getKey()}
                                  style={{
                                    backgroundColor: AppColors.white,
                                    color: AppColors.black,
                                    textAlign: "center",
                                    position: "sticky",
                                    left: 0,
                                    zIndex: 100,
                                    paddingLeft: 20,
                                    height: 70,
                                    border: "1px solid #adb8cc",
                                  }}
                                >
                                  <div style={{ width: 100 }}>
                                    <Typography className="font-bold fs12 gray6">
                                      {moment(dl).format("ddd DD")}
                                    </Typography>
                                  </div>
                                </TableCell>
                                {vetList?.length > 0 &&
                                  vetList?.map((vet, i) => {
                                    let aptType = "Others";

                                    const dayData = appointmentList?.filter(
                                      (ap) =>
                                        ap?.doctorId === vet?.doctorId &&
                                        ap?.appoinmentDate === dl
                                    );
                                    if (dayData?.length > 0) {
                                      const sur = dayData?.find(
                                        (dd) => dd?.serviceType === "Surgery"
                                      );
                                      const emer = dayData?.find(
                                        (dd) => dd?.serviceType === "Emergency"
                                      );
                                      const vacc = dayData?.find(
                                        (dd) =>
                                          dd?.serviceType === "Vaccination"
                                      );
                                      const consData = dayData?.find(
                                        (dd) =>
                                          dd?.serviceType === "Consultation"
                                      );

                                      aptType = sur
                                        ? "Surgery"
                                        : emer
                                        ? "Emergency"
                                        : vacc
                                        ? "Vaccination"
                                        : consData
                                        ? "Consultation"
                                        : "Others";
                                    }
                                    return (
                                      <TableCell
                                        key={getKey()}
                                        style={{
                                          backgroundColor: AppColors.white,
                                          color: AppColors.black,
                                          textAlign: "center",
                                          border: "1px solid #adb8cc",
                                        }}
                                      >
                                        <div style={{ width: 150, height: 60 }}>
                                          {dayData?.length > 0 && (
                                            <div className="appointment-box">
                                              <div
                                                className={
                                                  aptType === "Surgery"
                                                    ? "cal-gray-box"
                                                    : aptType === "Vaccination"
                                                    ? "cal-green-box"
                                                    : aptType === "Consultation"
                                                    ? "cal-blue-box"
                                                    : aptType === "Emergency"
                                                    ? "cal-red-box"
                                                    : "cal-rose-box"
                                                }
                                              >
                                                <div className="flex-column jus-con-spa-bet h50">
                                                  <div className="flex-row">
                                                    {dayData?.length > 0
                                                      ? dayData
                                                          ?.slice(0, 2)
                                                          ?.map((st, si) => (
                                                            <Typography
                                                              className={`${
                                                                si !== 0
                                                                  ? "ml2"
                                                                  : ""
                                                              } font-bold fs8 cal-time ${
                                                                aptType ===
                                                                "Vaccination"
                                                                  ? "green-back"
                                                                  : aptType ===
                                                                    "Consultation"
                                                                  ? "card-bot-blue-back"
                                                                  : aptType ===
                                                                    "Emergency"
                                                                  ? "card-bot-red-back"
                                                                  : "card-bot-rose-back"
                                                              }`}
                                                            >
                                                              {
                                                                st?.appoimentTime
                                                              }
                                                            </Typography>
                                                          ))
                                                      : null}
                                                  </div>
                                                  {dayData?.length > 2 ? (
                                                    <Typography className="font-bold fs10 card-black">
                                                      + {dayData?.length - 2}{" "}
                                                      more
                                                    </Typography>
                                                  ) : (
                                                    <div />
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </TableCell>
                                    );
                                  })}
                              </TableRow>
                            );
                          })}
                        {selectedType !== "week" &&
                          timeList?.length > 0 &&
                          timeList?.map((tl, i) => {
                            const currTime = moment().format("HH:mm");
                            const timeIsBet = isTimeBetween(currTime, tl);
                            return (
                              <TableRow
                                tabIndex={-1}
                                ref={timeIsBet ? timeRef : null}
                              >
                                <TableCell
                                  key={getKey()}
                                  style={{
                                    backgroundColor: AppColors.white,
                                    color: AppColors.black,
                                    textAlign: "center",
                                    position: "sticky",
                                    left: 0,
                                    zIndex: 100,
                                    paddingLeft: 20,
                                    height: 70,
                                    border: "1px solid #adb8cc",
                                  }}
                                >
                                  <div style={{ width: 100 }}>
                                    {selectedType === "week" ? (
                                      <div className="flex-center">{tl}</div>
                                    ) : (
                                      <Typography
                                        className={`font-bold fs12 ${
                                          timeIsBet ? "red-color" : "gray6"
                                        }`}
                                      >
                                        {tl}
                                      </Typography>
                                    )}
                                  </div>
                                </TableCell>
                                {vetList?.length > 0 &&
                                  vetList?.map((vt, ind) => {
                                    const timeMatch =
                                      reqApnts?.[
                                        `${tl}-${tl?.split(":")?.[0]}:59`
                                      ];
                                    const vetMatchRec = timeMatch?.filter(
                                      (tma) => tma?.doctorId === vt?.doctorId
                                    );
                                    return (
                                      <TableCell
                                        key={getKey()}
                                        style={{
                                          backgroundColor: AppColors.white,
                                          color: AppColors.black,
                                          textAlign: "center",
                                          border: "1px solid #adb8cc",
                                        }}
                                      >
                                        <div style={{ width: 600, height: 60 }}>
                                          {vetMatchRec?.length > 0 && (
                                            <>
                                              <div className="appointment-box">
                                                <div
                                                  className={`flex-row ${
                                                    vetMatchRec?.length > 1
                                                      ? "jus-con-spa-bet"
                                                      : ""
                                                  }`}
                                                >
                                                  {vetMatchRec?.length > 0 &&
                                                    vetMatchRec
                                                      ?.slice(0, 4)
                                                      ?.map((timMa, index) => {
                                                        return (
                                                          <div
                                                            className={`cursor ${
                                                              vetMatchRec?.length ===
                                                              1
                                                                ? "w100Per"
                                                                : vetMatchRec?.length ===
                                                                  2
                                                                ? "w49Per"
                                                                : vetMatchRec?.length ===
                                                                  3
                                                                ? "w32Per"
                                                                : "w23Per"
                                                            }`}
                                                            key={getKey()}
                                                            onClick={() =>
                                                              navDetailsPage(
                                                                timMa
                                                              )
                                                            }
                                                          >
                                                            <div
                                                              className={
                                                                timMa?.serviceType ===
                                                                "Surgery"
                                                                  ? "cal-gray-box"
                                                                  : timMa?.serviceType ===
                                                                    "Vaccination"
                                                                  ? "cal-green-box"
                                                                  : timMa?.serviceType ===
                                                                    "Consultation"
                                                                  ? "cal-blue-box"
                                                                  : timMa?.serviceType ===
                                                                    "Emergency"
                                                                  ? "cal-red-box"
                                                                  : "cal-rose-box"
                                                              }
                                                            >
                                                              <div className="flex-row">
                                                                <div className="flex-column jus-con-spa-bet h50">
                                                                  <div className="flex-row">
                                                                    {timMa?.petImage ? (
                                                                      <img
                                                                        src={
                                                                          timMa?.petImage
                                                                        }
                                                                        alt=""
                                                                        className="pet-card-img2"
                                                                      />
                                                                    ) : (
                                                                      <div className="flex-center h20img">
                                                                        <Typography className="font-bold fs30 white-color capitalize">
                                                                          {
                                                                            timMa
                                                                              ?.petName?.[0]
                                                                          }
                                                                        </Typography>
                                                                      </div>
                                                                    )}
                                                                    <div
                                                                      className={`ml10 txt-regular white-color fs8 card-consultation ${
                                                                        timMa?.appoinmentType ===
                                                                        "Physical"
                                                                          ? "card-con-blue-back"
                                                                          : timMa?.appoinmentType ===
                                                                            "Virtual"
                                                                          ? "virtual-bg-color"
                                                                          : "card-con-gray-back"
                                                                      }`}
                                                                    >
                                                                      {
                                                                        timMa?.appoinmentType
                                                                      }
                                                                    </div>
                                                                    <Typography
                                                                      className={`ml5 font-bold fs8 cal-time ${
                                                                        timMa?.serviceType ===
                                                                        "Vaccination"
                                                                          ? "green-back"
                                                                          : timMa?.serviceType ===
                                                                            "Consultation"
                                                                          ? "card-bot-blue-back"
                                                                          : timMa?.serviceType ===
                                                                            "Emergency"
                                                                          ? "card-bot-red-back"
                                                                          : "card-bot-rose-back"
                                                                      }`}
                                                                    >
                                                                      {
                                                                        timMa?.appoimentTime
                                                                      }
                                                                    </Typography>
                                                                  </div>
                                                                  <div className="flex-row">
                                                                    <Typography className="font-bold fs10 capitalize">
                                                                      {`${
                                                                        timMa?.serviceType ??
                                                                        ""
                                                                      } | ${
                                                                        timMa?.petName
                                                                      }`}
                                                                    </Typography>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        );
                                                      })}
                                                  {timeMatch?.length > 4 ? (
                                                    <div className="flex-center font-bold fs14 ml20">
                                                      + {timeMatch?.length - 4}{" "}
                                                      more
                                                    </div>
                                                  ) : null}
                                                </div>
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      </TableCell>
                                    );
                                  })}
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
                {selectedVet !== "all" && (
                  <TableContainer sx={{ maxHeight: 380 }}>
                    <Table
                      sx={{ minWidth: 200 }}
                      aria-labelledby="tableTitle"
                      stickyHeader
                    >
                      <TableHead>
                        <TableRow>
                          {selectedType === "week" &&
                            [{}, ...dateList]?.map((dt, i) => (
                              <TableCell
                                key={getKey()}
                                style={
                                  i === 0
                                    ? {
                                        backgroundColor: AppColors.white,
                                        color: AppColors.black,
                                        textAlign: "left",
                                        position: "sticky",
                                        left: 0,
                                        zIndex: 105,
                                        // paddingLeft: 20,
                                        border: "1px solid #adb8cc",
                                        height: 70,
                                      }
                                    : {
                                        backgroundColor: AppColors.white,
                                        color: AppColors.black,
                                        textAlign: "center",
                                        border: "1px solid #adb8cc",
                                        height: 70,
                                      }
                                }
                                sortDirection={false}
                                className={`table-header-text capitalize `}
                              >
                                {i === 0 ? (
                                  <div style={{ width: 100 }} />
                                ) : (
                                  <div
                                    style={{
                                      width: 200,
                                    }}
                                  >
                                    <Typography className="text-bold capitalize fs14">
                                      {moment(dt).format("ddd DD")}
                                    </Typography>
                                  </div>
                                )}
                              </TableCell>
                            ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {timeList?.length > 0 &&
                          timeList?.map((tm, i) => {
                            const currTime = moment().format("HH:mm");
                            const timeIsBet = isTimeBetween(currTime, tm);
                            const timeMatch =
                              reqApnts?.[`${tm}-${tm?.split(":")?.[0]}:59`];

                            const dateMatchRec = timeMatch?.filter(
                              (tma) =>
                                tma?.appoinmentDate ===
                                (selectedType === "today"
                                  ? moment().format("YYYY-MM-DD")
                                  : moment(new Date(selectedDate)).format(
                                      "YYYY-MM-DD"
                                    ))
                            );

                            return (
                              <TableRow
                                tabIndex={-1}
                                ref={timeIsBet ? timeRef : null}
                              >
                                <TableCell
                                  key={getKey()}
                                  style={{
                                    backgroundColor: AppColors.white,
                                    color: AppColors.black,
                                    textAlign: "center",
                                    position: "sticky",
                                    left: 0,
                                    zIndex: 100,
                                    paddingLeft: 20,
                                    height: 70,
                                    border: "1px solid #adb8cc",
                                  }}
                                >
                                  <div style={{ width: 100 }}>
                                    <Typography
                                      className={`font-bold fs12 ${
                                        timeIsBet ? "red-color" : "gray6"
                                      }`}
                                    >
                                      {tm}
                                    </Typography>
                                  </div>
                                </TableCell>
                                {selectedType !== "week" && (
                                  <TableCell
                                    key={getKey()}
                                    style={{
                                      backgroundColor: AppColors.white,
                                      color: AppColors.black,
                                      textAlign: "center",
                                      height: 70,
                                      border: "1px solid #adb8cc",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: window.innerWidth - 300,
                                        height: 60,
                                      }}
                                    >
                                      <div
                                        className={`flex-row ${
                                          dateMatchRec?.length > 1
                                            ? "jus-con-spa-bet"
                                            : ""
                                        }`}
                                      >
                                        {dateMatchRec?.length > 0
                                          ? dateMatchRec
                                              ?.slice(0, 4)
                                              ?.map((timMa, index) => {
                                                return (
                                                  <div
                                                    className={`cursor  ${
                                                      dateMatchRec?.length === 1
                                                        ? "w100Per"
                                                        : dateMatchRec?.length ===
                                                          2
                                                        ? "w49Per"
                                                        : dateMatchRec?.length ===
                                                          3
                                                        ? "w32Per"
                                                        : "w23Per"
                                                    }`}
                                                    key={getKey()}
                                                    onClick={() =>
                                                      navDetailsPage(timMa)
                                                    }
                                                  >
                                                    <div
                                                      className={
                                                        timMa?.serviceType ===
                                                        "Vaccination"
                                                          ? "cal-green-box"
                                                          : timMa?.serviceType ===
                                                            "Consultation"
                                                          ? "cal-blue-box"
                                                          : timMa?.serviceType ===
                                                            "Emergency"
                                                          ? "cal-red-box"
                                                          : "cal-rose-box"
                                                      }
                                                    >
                                                      <div className="flex-row">
                                                        <div className="flex-column jus-con-spa-bet h50">
                                                          <div className="flex-row">
                                                            {timMa?.petImage ? (
                                                              <img
                                                                src={
                                                                  timMa?.petImage
                                                                }
                                                                alt=""
                                                                className="pet-card-img2"
                                                              />
                                                            ) : (
                                                              <div className="flex-center h20img">
                                                                <Typography className="font-bold fs30 white-color capitalize">
                                                                  {
                                                                    timMa
                                                                      ?.petName?.[0]
                                                                  }
                                                                </Typography>
                                                              </div>
                                                            )}
                                                            <div
                                                              className={`ml10 txt-regular white-color fs8 card-consultation ${
                                                                timMa?.appoinmentType ===
                                                                "Physical"
                                                                  ? "card-con-blue-back"
                                                                  : timMa?.appoinmentType ===
                                                                    "Virtual"
                                                                  ? "virtual-bg-color"
                                                                  : "card-con-gray-back"
                                                              }`}
                                                            >
                                                              {
                                                                timMa?.appoinmentType
                                                              }
                                                            </div>
                                                            <Typography
                                                              className={`ml5 font-bold fs8 cal-time ${
                                                                timMa?.appoinmentType ===
                                                                "Physical"
                                                                  ? "card-con-blue-back"
                                                                  : timMa?.appoinmentType ===
                                                                    "Virtual"
                                                                  ? "virtual-bg-color"
                                                                  : "card-con-gray-back"
                                                              }`}
                                                            >
                                                              {
                                                                timMa?.appoimentTime
                                                              }
                                                            </Typography>
                                                          </div>
                                                          <div className="flex-row">
                                                            <Typography className="font-bold fs10 capitalize">
                                                              {`${
                                                                timMa?.serviceType ??
                                                                ""
                                                              } | ${
                                                                timMa?.petName
                                                              }`}
                                                            </Typography>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                );
                                              })
                                          : null}
                                      </div>
                                    </div>
                                  </TableCell>
                                )}
                                {selectedType === "week" &&
                                  dateList?.length > 0 &&
                                  dateList?.map((dt, i) => {
                                    const timeMatch =
                                      reqApnts?.[
                                        `${tm}-${tm?.split(":")?.[0]}:59`
                                      ];
                                    const reqList = timeMatch?.filter(
                                      (rl) => rl?.appoinmentDate === dt
                                    );
                                    let aptType = "Others";
                                    if (reqList?.length > 0) {
                                      const emer = reqList?.find(
                                        (dd) => dd?.serviceType === "Emergency"
                                      );
                                      const vacc = reqList?.find(
                                        (dd) =>
                                          dd?.serviceType === "Vaccination"
                                      );
                                      const consData = reqList?.find(
                                        (dd) =>
                                          dd?.serviceType === "Consultation"
                                      );

                                      aptType = emer
                                        ? "Emergency"
                                        : vacc
                                        ? "Vaccination"
                                        : consData
                                        ? "Consultation"
                                        : "Others";
                                    }

                                    return (
                                      <TableCell
                                        key={getKey()}
                                        style={{
                                          backgroundColor: AppColors.white,
                                          color: AppColors.black,
                                          textAlign: "center",
                                          height: 70,
                                          border: "1px solid #adb8cc",
                                        }}
                                      >
                                        <div style={{ width: 200, height: 60 }}>
                                          <div>
                                            {reqList?.length > 0 ? (
                                              <div
                                                className={
                                                  aptType === "Vaccination"
                                                    ? "cal-green-box"
                                                    : aptType === "Consultation"
                                                    ? "cal-blue-box"
                                                    : aptType === "Emergency"
                                                    ? "cal-red-box"
                                                    : "cal-rose-box"
                                                }
                                              >
                                                <div className="flex-column jus-con-spa-bet h50">
                                                  <div className="flex-row">
                                                    {reqList?.length > 0
                                                      ? reqList
                                                          ?.slice(0, 2)
                                                          ?.map((st, si) => (
                                                            <Typography
                                                              className={`${
                                                                si !== 0
                                                                  ? "ml2"
                                                                  : ""
                                                              } font-bold fs8 cal-time ${
                                                                aptType ===
                                                                "Vaccination"
                                                                  ? "green-back"
                                                                  : aptType ===
                                                                    "Consultation"
                                                                  ? "card-bot-blue-back"
                                                                  : aptType ===
                                                                    "Emergency"
                                                                  ? "card-bot-red-back"
                                                                  : "card-bot-rose-back"
                                                              }`}
                                                            >
                                                              {
                                                                st?.appoimentTime
                                                              }
                                                            </Typography>
                                                          ))
                                                      : null}
                                                  </div>
                                                  {reqList?.length > 2 ? (
                                                    <Typography className="font-bold fs10 card-black">
                                                      + {reqList?.length - 2}{" "}
                                                      more
                                                    </Typography>
                                                  ) : (
                                                    <div />
                                                  )}
                                                </div>
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                      </TableCell>
                                    );
                                  })}
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Grid>
            </div>
          </Container>
        </>
      ) : (
        <AppointmentApprove />
      )}
    </>
  );
};

export default Appointments;
