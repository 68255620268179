import { Container, Grid, Toolbar } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Select from "../../../components/Select/Select";

import TopBar from "../../../components/TopBar/TopBar";

import CustomButton from "../../../components/CustomButton";
import CustomTextField from "../../../components/CustomTextField";
import { getClinicPets } from "../../../redux/reducers/petSlice";
import "./ClinicPet.css";

import CustomCard from "../../../components/CustomCard/CustomCard";

import { AppColors } from "../../../util/AppColors";
import CreateClinicPet from "../../CommonComponents/CreateClinicPet";
import ClinicPetBookAppointment from "./ClinicPetBookAppointment";
import { createNewChat } from "../../../redux/reducers/chatSlice";

const ClinicPets = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pets = useSelector((state) => state?.pet?.clinicPets);
  const [tablePets, setTablePets] = useState([]);
  const [modalBookVisible, setModalBookVisible] = useState(false);
  const [searchTypeValue, setSearchTypeValue] = useState("");
  const [selectPet, setSelectPet] = useState(null);
  const [createModVisible, setCreateModalVisible] = useState(false);
  const defaultUrl = `?type=all&search=${searchTypeValue}`;

  useEffect(() => {
    getClinicPetsData();
  }, []);

  useEffect(() => {
    const reqList = getSortedPets()?.filter((li) => {
      const pet = li;
      const petNameMatch = pet?.petName
        ?.toLowerCase()
        .includes(searchTypeValue?.toLowerCase());
      const userNameMatch = pet?.userName
        ?.toLowerCase()
        .includes(searchTypeValue?.toLowerCase());
      const userEmailMatch = pet?.userEmail
        ?.toLowerCase()
        .includes(searchTypeValue?.toLowerCase());
      const userMobileMatch = pet?.userMobile
        ?.toLowerCase()
        .includes(searchTypeValue?.toLowerCase());

      return petNameMatch || userNameMatch || userEmailMatch || userMobileMatch;
    });
    setTablePets(reqList);
  }, [searchTypeValue, pets?.pets]);

  const getSortedPets = () => {
    return pets?.pets
      ?.map((pet) => ({
        appointmentId: pet?.appointmentId,
        ...pet?.listOfDatas,
      }))
      ?.sort((a, b) => {
        if (a?.petName?.toLowerCase() < b?.petName?.toLowerCase()) return -1;
        if (a?.petName?.toLowerCase() > b?.petName?.toLowerCase()) return 1;
        return 0;
      });
  };

  const bookAppointment = (li) => {
    setSelectPet(li);
    setModalBookVisible(true);
  };

  const handleCreate = () => {
    setCreateModalVisible(true);
  };

  const getClinicPetsData = () => {
    dispatch(getClinicPets(defaultUrl));
  };

  const onCloseCreateMod = (e) => {
    if (e === "petCreated") getClinicPetsData();
    setCreateModalVisible(!createModVisible);
  };

  const handleClickPet = (pet) => {
    return navigate("/clinic-pet-details", {
      state: {
        appointment: {
          appoinment: {
            appoimentId: pet?.appointmentId ?? null,
            petId: pet?.petId,
          },
        },
      },
    });
  };

  const onClickMessageIcon = (e) => {
    dispatch(createNewChat(e?.userId)).then((res) => {
      if (res?.payload) {
        navigate("/chat", { state: { chatId: res?.payload?.chatId } });
      }
    });
  };

  return (
    <>
      <TopBar>
        <Toolbar variant="regular">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="flex-row">
              <div className="top-row-cen-con w100Per">
                <Grid item xs={4} sm={5} md={5} lg={5} xl={5}>
                  <CustomTextField
                    placeholder="Search"
                    name="name"
                    fullWidth
                    handleChange={(e) => setSearchTypeValue(e.target.value)}
                    value={searchTypeValue}
                    search
                    backgroundColor={AppColors.lightPink}
                  />
                </Grid>
                <Grid item xs={4} sm={2} md={2} lg={1} xl={1} className="ml20">
                  <CustomButton text="Create" onClick={handleCreate} />
                </Grid>

                <div className="top-row-right-con w15Per topBar-select">
                  <Select
                    list={[
                      { name: "All", value: "all" },
                      { name: "Recent", value: "recent" },
                    ]}
                    value={"all"}
                    // handleChange={(e) => handleSelect(e, "location")}
                    name="vet"
                    select
                  />
                </div>
              </div>
            </div>
          </Grid>
        </Toolbar>
      </TopBar>
      <Container maxWidth="xl" className="scroll-80vh">
        <CustomCard
          list={tablePets}
          pets
          onBookClick={bookAppointment}
          onCardClick={handleClickPet}
          onClickMessageIcon={onClickMessageIcon}
        />
        <div className="pb30" />
      </Container>
      <ClinicPetBookAppointment
        modalVisible={modalBookVisible}
        setModalBookVisible={setModalBookVisible}
        selectPet={selectPet}
      />

      <CreateClinicPet
        createModVisible={createModVisible}
        onCloseCreateMod={onCloseCreateMod}
      />
    </>
  );
};

export default ClinicPets;
