import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoginLogo from "../../../assets/images/png/login.png";
import VetLogo from "../../../assets/images/png/VetInstantNew.png";
import CustomButton from "../../../components/CustomButton";
import CustomTextField from "../../../components/CustomTextField";
import { forgotPassword } from "../../../redux/reducers/authSlice";
import { ErrorStrings } from "../../../util/ErrorString";
import { EmailRegex } from "../../../util/Validations";

const initialValues = { email: "" };
const initialError = { email: false };
const initialHelp = { email: "" };

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loginValues, setLoginValues] = useState(initialValues);
  const [loginErrors, setLoginErrors] = useState(initialError);
  const [loginHelps, setLoginHelps] = useState(initialHelp);
  const [isError, setError] = useState(false);

  const handleChange = (e) => {
    if (e.target.name === "email") {
      emailValidation(e.target.value);
    }
    setLoginValues({ ...loginValues, [e.target.name]: e.target.value });
  };

  const emailValidation = (value) => {
    if (value === "") {
      setLoginErrors({ ...loginErrors, email: true });
      setLoginHelps({ ...loginHelps, email: ErrorStrings.emptyEmail });
      return;
    }
    if (!EmailRegex.test(value)) {
      setLoginErrors({ ...loginErrors, email: true });
      setLoginHelps({ ...loginHelps, email: ErrorStrings.inValidEmail });
      return;
    }
    setLoginErrors({ ...loginErrors, email: false });
    setLoginHelps({ ...loginHelps, email: "" });
  };

  const getValidation = () => {
    const emailValue = loginValues?.email?.trim();

    if (emailValue === "") {
      setLoginErrors({ ...loginErrors, email: true });
      setLoginHelps({ ...loginHelps, email: ErrorStrings.emptyEmail });
    }
    if (emailValue !== "") {
      setLoginErrors({
        ...loginErrors,
        email: !EmailRegex.test(emailValue) ? true : false,
      });
      setLoginHelps({
        ...loginHelps,
        email: !EmailRegex.test(emailValue) ? ErrorStrings.inValidEmail : "",
      });
    }

    if (emailValue === "") {
      setLoginErrors({ ...loginErrors, email: true });
      setLoginHelps({ ...loginHelps, email: ErrorStrings.emptyEmail });
    }

    if (emailValue !== "") {
      setLoginErrors({
        ...loginErrors,
        email: !EmailRegex.test(emailValue) ? true : false,
      });
      setLoginHelps({
        ...loginHelps,
        email: !EmailRegex.test(emailValue) ? ErrorStrings.inValidEmail : "",
      });
    }
  };

  const handleLogin = async (e) => {
    if (e.keyCode === 13) {
      getValidation();
      if (loginErrors?.email) return;
      if (loginValues?.email === "" || !loginValues?.email) return;
      dispatch(forgotPassword({ emailId: loginValues?.email })).then((res) => {
        if (res?.payload) {
          navigate("/login");
        }
      });
    }
  };

  return (
    <div className="Login-main-container" onKeyDown={handleLogin}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <div className="Login-right-con">
            <div className="Login-left-top">
              <img src={VetLogo} alt="" className="Login-vet-logo" />
            </div>

            <div className="mh15Per">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography className="font-bold fs30 mv20 flex-start black3">
                    Forgot Password?
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography className="txt-regular fs14 black3 mb10">
                    Enter Your Registered Email Id
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <CustomTextField
                    placeholder="Enter Your Registered Email Id"
                    name="email"
                    fullWidth
                    handleChange={handleChange}
                    value={loginValues?.email}
                    helperText={loginHelps?.email}
                    error={loginErrors?.email}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="Login-center">
                    <div className="Login-btn">
                      <CustomButton
                        text="Submit"
                        onClick={() => handleLogin({ keyCode: 13 })}
                      />
                    </div>
                  </div>
                </Grid>

                {isError ? (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="flex-center">
                      <Typography className="txt-regular fs14 red4">
                        {isError}
                      </Typography>
                    </div>
                  </Grid>
                ) : null}
              </Grid>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <div className="Login-left-con" id="loginLeftCon">
            <div className="flex1-center">
              <img src={LoginLogo} alt="" className="Login-main-img" />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ForgotPassword;
