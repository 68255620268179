export const productHeaders = [
  "productName",
  "tradeName",
  "category",
  "subCategory",
  "tax",
  "unitSellingPrice",
  "subUnitSellingPrice",
];

export const serviceHeaders = [
  "serviceName",
  "category",
  "subCategory",
  "sellingPrice",
  "tax",
];

export const petsHeaders = [
  "name",
  "parentName",
  "species",
  "breed",
  "gender",
  "age",
  "reproductiveStatus",
];

export const appointmentHeaders = [
  "apntPetName",
  "parentName",
  "vetName",
  "consultationType",
  "category",
];

export const paymentsHeaders = [
  "payPetName",
  "vetName",
  "balanceDue",
  "consultationType",
  "paymentStatus",
];

export const stockHeaders = [
  "name",
  "category",
  "supplierName",
  "quantity",
  "batchNo",
  "price",
];
