import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authSlice, { logout } from "./reducers/authSlice";
import clinicSlice from "./reducers/clinicSlice";
import loaderSlice from "./reducers/loaderSlice";
import petSlice from "./reducers/petSlice";
import snackSlice from "./reducers/snackSlice";
import vetSlice from "./reducers/vetSlice";
import paymentSlice from "./reducers/paymentSlice";
import doctorSlice from "./reducers/doctorSlice";
import medicalHistorySlice from "./reducers/medicalHistorySlice";
import notificationSlice from "./reducers/notificationSlice";
import mixedSlice from "./reducers/mixedSlice";
import recorderSlice from "./reducers/recorderSlice";
import chatSlice from "./reducers/chatSlice";

const appReducer = combineReducers({
  auth: authSlice,
  loader: loaderSlice,
  snack: snackSlice,
  clinic: clinicSlice,
  pet: petSlice,
  vet: vetSlice,
  payment: paymentSlice,
  doctor: doctorSlice,
  medicalHistory: medicalHistorySlice,
  notification: notificationSlice,
  mixed: mixedSlice,
  recorder: recorderSlice,
  chat: chatSlice,
});

const rootReducer = (state, action) => {
  if (action.type === logout.type) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disables the serializable check
    }),
});
