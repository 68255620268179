import { Container } from "@mui/material";
import { useState } from "react";
import CustomButton from "../../../../components/CustomButton";
import DownloadReports from "./DownloadReports";

const Reports = () => {
  const [selectedTab, setSelectedTab] = useState("DownloadReports");

  const handleSelectedTab = (tab) => {
    setSelectedTab(tab);
  };

  return (
    <Container maxWidth="xl">
      <div className="com-mar back-white p20 ">
        <div className="flex-row-ali-cen">
          <div>
            <CustomButton
              text="Overview"
              smallBtn
              tabOutlined
              yellowBtn={selectedTab === "Overview"}
              onClick={() => handleSelectedTab("Overview")}
            />
          </div>
          <div className="tab-split-tab-ver-bar mh15" />
          <div>
            <CustomButton
              text="Download Reports"
              smallBtn
              tabOutlined
              yellowBtn={selectedTab === "DownloadReports"}
              onClick={() => handleSelectedTab("DownloadReports")}
            />
          </div>
        </div>
        {selectedTab === "Overview" ? null : <DownloadReports />}
      </div>
    </Container>
  );
};

export default Reports;
