import { createSlice } from "@reduxjs/toolkit";

export const recorderSlice = createSlice({
  name: "recorder",
  initialState: {
    recording: false,
    audSaved: false,
    visible: false,
    audioBlobData: null,
  },
  reducers: {
    setAudRecording: (state, action) => {
      state.recording = action.payload;
    },
    setAudSaved: (state, action) => {
      state.audSaved = action.payload;
    },
    setAudRecordingVisbility: (state, action) => {
      state.visible = action.payload;
    },
    setAudioBlobData: (state, action) => {
      state.audioBlobData = action.payload;
    },
  },
});

export const {
  setAudRecording,
  setAudSaved,
  setAudRecordingVisbility,
  setAudioBlobData,
} = recorderSlice.actions;

export default recorderSlice.reducer;
