import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Collapse,
  Container,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { groupBy, uniqBy } from "lodash";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../components/CustomButton";
import CustomModal from "../../../components/CustomModal/CustomModal";
import CustomTextField from "../../../components/CustomTextField";
import Select from "../../../components/Select/Select";
import {
  createStock,
  getProductsByClinic,
  getProductsByClinicWithFilter,
  getStocksByClinic,
  getSupplier,
  updateStockById,
} from "../../../redux/reducers/clinicSlice";
import { AppColors } from "../../../util/AppColors";
import { productCategoryList } from "../../../util/dropList";

const tableHeaders = [
  // "productCode",
  "productName",
  "category",
  "unitType",
  "quantity",
  "extraStatus",
];

const nameExpan = {
  supplierName: "Supplier Name",
  category: "Category",
  productName: "Product Name",
  batchNo: "Batch No",
  quantity: "Quantity",
  updatedQuantity: "Updated Quantity",
  location: "Location",
  productCode: "Product Code",
  unitType: "Unit Type",
  extraStatus: "",
  purchaseCost: "Purchase Cost",
};

const tempTableData = [
  {
    productCode: "Supplier",
    productName: "RajKumar",
    category: "rajkumar@yopmail.com",
    unitType: "(767)72676",
    quantity: "Clinic",
    extraStatus: (
      <div className="flex-row">
        <img
          src={require("../../../assets/images/png/editNew.png")}
          alt=""
          className="imghw30 cursor"
        />
      </div>
    ),
  },
];

const requiredFieldList = [
  "supplierName",
  "category",
  "productName",
  "batchNo",
  "quantity",
  "location",
  "purchaseCost",
];

const initValues = {
  date: new Date(),
  supplierName: null,
  category: null,
  productName: null,
  batchNo: "",
  quantity: "",
  updatedQuantity: "",
  mfgDate: null,
  expiryDate: null,
  purchaseCost: "",
  location: "",
  remarks: "",
};

const initHelps = {
  date: "",
  supplierName: "",
  category: "",
  productName: "",
  batchNo: "",
  quantity: "",
  mfgDate: "",
  expiryDate: "",
  purchaseCost: "",
  location: "",
  remarks: "",
};

const initErrors = {
  date: false,
  supplierName: false,
  category: false,
  productName: false,
  batchNo: false,
  quantity: false,
  mfgDate: false,
  expiryDate: false,
  purchaseCost: false,
  location: false,
  remarks: false,
};

const tblGrayBack = {
  backgroundColor: AppColors.tableGrey,
  color: AppColors.white,
  textAlign: "left",
};

const Stock = () => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [tableStocks, setTableStocks] = useState(tempTableData);
  const [stockValues, setStockValues] = useState(initValues);
  const [stockErrors, setStockErrors] = useState(initValues);
  const [stockHelps, setStockHelps] = useState(initHelps);
  const [modVsble, setModVsble] = useState(false);
  const [stockId, setStockId] = useState(null);
  const [page, setPage] = useState(1);
  const rowsPerPage = 10;
  const [supplierList, setSupplierList] = useState([]);
  const [productList, setProductList] = useState([]);
  const { suppliers, filteredProducts, stocks, products } = useSelector(
    (state) => state?.clinic
  );
  const defaultUrl = `?page=1&itemSize=${rowsPerPage}`;
  const [uniqStockList, setUniqStockList] = useState([]);
  const [groupedStocks, setGrouppedStocks] = useState(null);
  const [isEdit, setEdit] = useState(false);
  const [batchList, setBatchList] = useState(null);

  useEffect(() => {
    dispatch(getStocksByClinic(defaultUrl));
    dispatch(getSupplier());
    dispatch(getProductsByClinic());
  }, []);

  useEffect(() => {
    const list = [];
    const reqGroupped = groupBy(stocks?.data, (item) => {
      list.push({
        category: item?.["category"],
        productName: item?.["productName"],
        unitType: "Test",
        quantity: item?.["quantity"],
      });
      return [item?.["category"], item?.["productName"]];
    });
    setGrouppedStocks(reqGroupped);
    const uniqList = uniqBy(list, (o) => o?.category && o?.productName);

    const reqList = uniqList?.map((li) => {
      const reqProd = products?.data?.find(
        (prd) => prd?.category === li?.category && prd?.name === li?.productName
      );
      return {
        category: li?.category,
        productName: li?.productName,
        unitType: reqProd?.measurementUnit ?? "",
        quantity: reqGroupped?.[`${li?.category},${li?.productName}`]?.reduce(
          (n, { quantity }) => n + quantity,
          0
        ),
      };
    });
    setUniqStockList(uniqBy(reqList, (o) => o?.category && o?.productName));
  }, [stocks]);

  useEffect(() => {
    dispatch(
      getStocksByClinic(
        `?page=${page}&itemSize=${rowsPerPage}&search=${searchValue}`
      )
    );
  }, [searchValue]);

  useEffect(() => {
    const reqList = suppliers?.data?.map((sup) => ({
      name: sup?.supplierName,
      value: sup?.supplierName,
    }));
    setSupplierList(reqList);
  }, [suppliers]);

  useEffect(() => {
    const reqList = filteredProducts?.map((prd) => ({
      ...prd,
      name: prd?.name,
      value: prd?.name,
    }));
    setProductList(reqList);
  }, [filteredProducts]);

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    setPage(1);
    setTableStocks([]);
  };

  const handleCreateUpdate = () => {
    setModVsble(true);
  };

  const handleCloseMod = () => {
    setEdit(false);
    setModVsble(false);
    setStockId(null);
    setStockValues(initValues);
    setStockHelps(initValues);
    setStockErrors(initErrors);
  };

  const handleChange = (e) => {
    const { name, value } = e?.target;
    const valObj = { [name]: value };
    const reqErrors = { ...stockErrors, [name]: value === "" ? true : false };
    const reqHelps = {
      ...stockHelps,
      [name]: value === "" ? `${nameExpan?.[name]} is required field` : "",
    };
    if (name === "category") {
      dispatch(getProductsByClinicWithFilter(`?search=${value}`));
    }
    if (name === "productName") {
      const reqProduct = productList?.find((prd) => prd?.name === value);
      valObj.batchNo = reqProduct?.batchNumber ?? "";
      valObj.location = reqProduct?.location ?? "";
      reqErrors.batchNo = reqProduct?.batchNumber?.length > 0 ? false : true;
      reqErrors.location = reqProduct?.location?.length > 0 ? false : true;
      reqHelps.batchNo =
        reqProduct?.batchNumber?.length > 0
          ? ""
          : `${nameExpan?.batchNo} is required field`;
      reqHelps.location =
        reqProduct?.location?.length > 0
          ? ""
          : `${nameExpan?.location} is required field`;
      if (new Date(reqProduct?.manufacturingDate) instanceof Date) {
        valObj.mfgDate = new Date(reqProduct?.manufacturingDate);
      }
      if (new Date(reqProduct?.expiryDate) instanceof Date) {
        valObj.expiryDate = new Date(reqProduct?.expiryDate);
      }
    }
    if (name === "batchNo") {
      if (isEdit) {
        const reqStock = stocks?.data?.find((stk) => stk?.batchNo === value);
        if (reqStock) {
          if (reqStock?.supplierName) {
            valObj.supplierName = reqStock?.supplierName;
          }
          if (reqStock?.category) {
            valObj.category = reqStock?.category;
            dispatch(
              getProductsByClinicWithFilter(`?search=${reqStock?.category}`)
            );
          }
          if (reqStock?.productName) {
            valObj.productName = reqStock?.productName;
          }
          if (reqStock?.quantity) {
            valObj.quantity = reqStock?.quantity;
          }
          if (reqStock?.updatedQuantity) {
            valObj.updatedQuantity = reqStock?.updatedQuantity;
          }
          if (reqStock?.mfgDate) {
            valObj.mfgDate = reqStock?.mfgDate;
          }
          if (reqStock?.expiryDate) {
            valObj.expiryDate = reqStock?.expiryDate;
          }
          if (reqStock?.purchaseCost) {
            valObj.purchaseCost = reqStock?.purchaseCost;
          }
          if (reqStock?.location) {
            valObj.location = reqStock?.location;
          }
          if (reqStock?.remark) {
            valObj.remarks = reqStock?.remark;
          }
        }
      }
    }
    setStockValues({ ...stockValues, ...valObj });
    setStockErrors(reqErrors);
    setStockHelps(reqHelps);
  };

  const handleSuccess = () => {
    dispatch(getStocksByClinic(defaultUrl));
    setModVsble(false);
    setStockValues(initValues);
    setStockErrors(initErrors);
    setStockHelps(initHelps);
  };

  const handleSave = () => {
    let errObj = {};
    let hlpObj = {};
    requiredFieldList?.forEach((fld) => {
      if (stockValues?.[fld]?.length === 0 || !stockValues?.[fld]) {
        errObj = { ...errObj, [fld]: true };
        hlpObj = { ...hlpObj, [fld]: `${nameExpan?.[fld]} is required field` };
      } else {
        errObj = { ...errObj, [fld]: false };
        hlpObj = { ...hlpObj, [fld]: "" };
      }
    });
    setStockErrors({ ...stockErrors, ...errObj });
    setStockHelps({ ...stockHelps, ...hlpObj });
    if (Object.keys(errObj)?.some((val) => errObj?.[val] === true)) return;
    const data = {
      date: moment(new Date(stockValues?.date)).format("YYYY-MM-DD"),
      supplierName: stockValues?.supplierName,
      category: stockValues?.category,
      productName: stockValues?.productName,
      batchNo: stockValues?.batchNo,
      quantity: stockValues?.quantity,
      location: stockValues?.location,
      remark: stockValues?.remarks,
      purchaseCost: stockValues?.purchaseCost,
      updatedQuantity: stockId ? stockValues?.updatedQuantity : 0,
    };
    if (
      stockValues?.mfgDate !== null &&
      new Date(stockValues?.mfgDate) instanceof Date
    ) {
      data.mfgDate = moment(new Date(stockValues?.mfgDate)).format(
        "YYYY-MM-DD"
      );
    }
    if (
      stockValues?.expiryDate !== null &&
      new Date(stockValues?.expiryDate) instanceof Date
    ) {
      data.expiryDate = moment(new Date(stockValues?.expiryDate)).format(
        "YYYY-MM-DD"
      );
    }

    if (stockId) {
      dispatch(updateStockById({ id: stockId, data })).then((res) => {
        if (res?.payload) handleSuccess();
      });
    } else {
      dispatch(createStock(data)).then((res) => {
        if (res?.payload) handleSuccess();
      });
    }
  };

  const handleEditStock = (itm) => {
    // setStockId(itm?.stockId);
    setEdit(true);
    const reqName = `${itm?.category},${itm?.productName}`;
    const reqBatchList = groupedStocks?.[reqName]?.map((li) => ({
      name: li?.batchNo,
      value: li?.batchNo,
    }));
    if (reqBatchList?.[0]) {
      const reqData = groupedStocks?.[reqName]?.[0];
      if (reqData?.category) {
        dispatch(getProductsByClinicWithFilter(`?search=${reqData?.category}`));
      }
      setStockValues({
        ...stockValues,
        date: new Date(reqData?.date),
        supplierName: reqData?.supplierName,
        category: reqData?.category,
        productName: reqData?.productName,
        batchNo: reqData?.batchNo,
        quantity: reqData?.quantity,
        mfgDate: new Date(reqData?.mfgDate),
        expiryDate: new Date(reqData?.expiryDate),
        location: reqData?.location,
        remarks: reqData?.remark,
      });
    }
    setBatchList(reqBatchList);
    setModVsble(true);
  };

  const TableDataRow = ({ data }) => {
    const [open, setOpen] = useState(false);
    const reqList = groupedStocks?.[`${data?.category},${data?.productName}`];

    return (
      <Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell style={{ width: 50 }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
            </IconButton>
          </TableCell>
          {tableHeaders?.map((th) => (
            <TableCell>
              {th === "extraStatus" ? (
                <img
                  alt=""
                  src={require("../../../assets/images/png/editNew.png")}
                  className="cursor imghw30"
                  onClick={() => handleEditStock(data)}
                />
              ) : (
                data?.[th]
              )}
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box style={{ background: "#D9D9D933" }}>
                <div className="ph20pv10 flex-row flex-row-wrap">
                  {reqList?.length > 0 &&
                    reqList?.map((li) => (
                      <div className="w33Per flex-row mv10" key={li?.stockId}>
                        <div>
                          <Typography className="font-medium fs12 blue-color">
                            Batch No: {li?.batchNo}
                          </Typography>
                          <Typography className="font-medium fs12 black11">
                            Mfg Date: :{" "}
                            {moment(new Date(li?.mfgDate)).format("YYYY-MM-DD")}
                          </Typography>
                          <Typography className="font-medium fs12 black11">
                            Expiry Date:{" "}
                            {moment(new Date(li?.expiryDate)).format(
                              "YYYY-MM-DD"
                            )}
                          </Typography>
                          <Typography className="font-medium fs12 black11">
                            Quantity: {li?.quantity}
                          </Typography>
                        </div>
                        {/* <div className="flex1-end pr10">
                        <img
                          alt=""
                          src={require("../../../assets/images/png/edit2.png")}
                          className="cursor img-wh20"
                          onClick={() => handleEditStock(li)}
                        />
                      </div> */}
                      </div>
                    ))}
                </div>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  };

  return (
    <Container maxWidth="xl">
      <div className="com-mar back-white pv10">
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <div className="flex-row">
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ width: "600px" }}
            >
              <CustomTextField
                search
                placeholder="Search"
                fullWidth
                backgroundColor={AppColors.lightPink}
                value={searchValue}
                handleChange={handleSearch}
              />
            </Grid>
          </div>
          <div className="w5Per ml20">
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
              <div>
                <CustomButton
                  text="Create"
                  smallBtn
                  onClick={handleCreateUpdate}
                />
              </div>
            </Grid>
          </div>
          {/* <div className="pos-absolute r80">
            <Typography className="font-medium fs16">
              Total number of services: {services?.totalRecords}
            </Typography>
          </div> */}
        </Grid>

        {/* <Table
          columns={tableHeaders}
          datas={tableStocks}
          page={page}
          rowsPerPage={rowsPerPage}
          totalRecords={10}
          //   handleChangePage={handleChangePage}
          grey
        /> */}

        <Box className="pb-40">
          <TableContainer sx={{ maxHeight: 400 }}>
            <Table aria-label="collapsible table" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell style={tblGrayBack} />
                  {tableHeaders?.map((th) => (
                    <TableCell style={tblGrayBack}>{nameExpan?.[th]}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {uniqStockList?.length > 0 &&
                  uniqStockList?.map((stock) => (
                    <TableDataRow
                      key={stock?.category + stock?.productName}
                      data={stock}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </div>
      <CustomModal
        open={modVsble}
        onClose={handleCloseMod}
        header="Stock Transaction"
        modalWidth={30}
        rightModal
      >
        <div className="scroll-90vh">
          <Grid container spacing={2} className="ph20">
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="txt-semi-bold fs14 light-grey">
                Date <span className="red-color">*</span>
              </div>

              <CustomTextField
                name="date"
                fullWidth
                handleChange={handleChange}
                value={stockValues?.date}
                mobileDate
                disabled={stockId}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="txt-semi-bold fs14 light-grey">
                Supplier Name <span className="red-color">*</span>
              </div>
              <Select
                list={supplierList}
                value={stockValues?.supplierName}
                handleChange={handleChange}
                name="supplierName"
                select
                helperText={stockHelps?.supplierName}
                error={stockErrors?.supplierName}
                labelTop
                disabled={stockId}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="txt-semi-bold fs14 light-grey">
                Category <span className="red-color">*</span>
              </div>
              <Select
                list={productCategoryList}
                value={stockValues?.category}
                handleChange={handleChange}
                name="category"
                select
                helperText={stockHelps?.category}
                error={stockErrors?.category}
                labelTop
                disabled={stockId}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="txt-semi-bold fs14 light-grey">
                Product Name <span className="red-color">*</span>
              </div>
              <Select
                list={productList}
                value={stockValues?.productName}
                handleChange={handleChange}
                name="productName"
                select
                helperText={stockHelps?.productName}
                error={stockErrors?.productName}
                labelTop
                disabled={stockId}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="txt-semi-bold fs14 light-grey">
                Batch No <span className="red-color">*</span>
              </div>
              {isEdit ? (
                <Select
                  list={batchList}
                  value={stockValues?.batchNo}
                  handleChange={handleChange}
                  name="batchNo"
                  select
                  helperText={stockHelps?.batchNo}
                  error={stockErrors?.batchNo}
                  labelTop
                  disabled={stockId}
                />
              ) : (
                <CustomTextField
                  name="batchNo"
                  fullWidth
                  value={stockValues?.batchNo}
                  error={stockErrors?.batchNo}
                  helperText={stockHelps?.batchNo}
                  handleChange={handleChange}
                />
              )}
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="txt-semi-bold fs14 light-grey">
                Mfg Date
                {/* <span className="red-color">*</span> */}
              </div>

              <CustomTextField
                name="mfgDate"
                fullWidth
                handleChange={handleChange}
                value={stockValues?.mfgDate}
                mobileDate
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="txt-semi-bold fs14 light-grey">
                Expiry Date
                {/* <span className="red-color">*</span> */}
              </div>

              <CustomTextField
                name="expiryDate"
                fullWidth
                handleChange={handleChange}
                value={stockValues?.expiryDate}
                mobileDate
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="txt-semi-bold fs14 light-grey">
                Location <span className="red-color">*</span>
              </div>
              <CustomTextField
                name="location"
                fullWidth
                value={stockValues?.location}
                error={stockErrors?.location}
                helperText={stockHelps?.location}
                handleChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="txt-semi-bold fs14 light-grey">
                Purchase Cost <span className="red-color">*</span>
              </div>
              <CustomTextField
                name="purchaseCost"
                fullWidth
                value={stockValues?.purchaseCost}
                error={stockErrors?.purchaseCost}
                helperText={stockHelps?.purchaseCost}
                handleChange={handleChange}
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="txt-semi-bold fs14 light-grey">
                Quantity <span className="red-color">*</span>
              </div>
              <CustomTextField
                name="quantity"
                fullWidth
                value={stockValues?.quantity}
                error={stockErrors?.quantity}
                helperText={stockHelps?.quantity}
                handleChange={handleChange}
                disabled={stockId}
                type="number"
              />
            </Grid>
            {stockId && (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="txt-semi-bold fs14 light-grey">
                  Difference in Quantity <span className="red-color">*</span>
                </div>
                <CustomTextField
                  name="updatedQuantity"
                  fullWidth
                  value={stockValues?.updatedQuantity}
                  error={stockErrors?.updatedQuantity}
                  helperText={stockHelps?.updatedQuantity}
                  handleChange={handleChange}
                  type="number"
                />
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="txt-semi-bold fs14 light-grey">Remarks</div>
              <CustomTextField
                name="remarks"
                fullWidth
                value={stockValues?.remarks}
                error={stockErrors?.remarks}
                helperText={stockHelps?.remarks}
                handleChange={handleChange}
                multiline
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="flex1-end mt10">
                <div>
                  <CustomButton
                    text={stockId ? "Update" : "Submit"}
                    smallBtn
                    onClick={handleSave}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </CustomModal>
    </Container>
  );
};

export default Stock;
