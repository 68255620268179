import { FormHelperText, Grid, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../../components/CustomButton";
import Checkbox from "../../../components/CustomCheckbox";
import CustomTextField from "../../../components/CustomTextField";
import {
  deleteClinicHolidayById,
  getClinicHolidays,
  getClinicOpeningHours,
  updateClinicHolidays,
  updateClinicOpeningHours,
} from "../../../redux/reducers/clinicSlice";
import { weekArray } from "../../../util/arrayList";
import { validateTimeSlots } from "../../../util/function";
import { initSlot } from "../../../util/object";
import "./BranchManagement.css";
import BranchManagementMain from "./BranchManagementMain";

const initialValues = { date: new Date(), holliday: "", i: 0 };

const ClinicConsultation = () => {
  const dispatch = useDispatch();
  const { openingHours } = useSelector((state) => state?.clinic);
  const holidays = useSelector((state) => state?.clinic?.holidays);
  const [isEditEnabled, setEditEnabled] = useState(false);
  const [dayValues, setDayValues] = useState(weekArray);
  const [duration, setDuration] = useState("15");
  const [occasionError, setOccasionError] = useState(false);
  const [noSlotErr, setNoSlotErr] = useState(false);
  const [timeErr, setTimeErr] = useState(false);
  const [holiday, setHoliday] = useState([]);

  useEffect(() => {
    dispatch(getClinicOpeningHours());
    dispatch(getClinicHolidays());
  }, []);

  useEffect(() => {
    getRequiredDateList();
  }, [openingHours]);

  const getRequiredDateList = () => {
    if (openingHours?.length > 0) {
      const reqArr = weekArray?.map((day) => {
        const dbDay = openingHours?.find((pa) => pa?.day === day?.value);
        if (dbDay) {
          const reqTimes = dbDay?.times?.map((tm, i) => {
            const [hour, min] = tm?.startTime?.split(":");
            const [endHour, endMin] = tm?.endTime?.split(":");
            return {
              startTime: new Date(new Date().setHours(hour, min)),
              endTime: new Date(new Date().setHours(endHour, endMin)),
              i,
            };
          });
          return {
            ...day,
            isSelected: dbDay?.status,
            slots: reqTimes,
          };
        }
        return day;
      });
      setDayValues(reqArr);
    }
  };

  const handleDelHoliday = (holId) => {
    dispatch(deleteClinicHolidayById(holId)).then((res) => {
      if (res?.payload) {
        dispatch(getClinicHolidays());
      }
    });
  };

  const handleSaveHoliday = async () => {
    const holidayValues = holiday?.[0];
    if (holidayValues?.holliday?.length === 0 || !holidayValues?.holliday) {
      return setOccasionError(true);
    }
    const apiRes = await dispatch(
      updateClinicHolidays({
        ...holidayValues,
        date: moment(new Date(holidayValues?.date)).format("YYYY-MM-DD"),
      })
    );
    if (apiRes?.payload) {
      dispatch(getClinicHolidays());
      setHoliday([]);
    }
  };

  const handleChageHolliday = (e) => {
    const { name, value } = e?.target;
    const reqDat = { ...holiday?.[0], [name]: value };
    setHoliday([reqDat]);
    if (name === "holliday") {
      setOccasionError(value?.length > 0 ? false : true);
    }
  };

  const handleGlobalTimeSave = async () => {
    if (
      validateTimeSlots(
        dayValues?.filter((d) => d?.isSelected),
        duration
      )
    ) {
      setTimeErr(false);
      const data = dayValues?.map((d) => {
        return {
          status: d?.isSelected,
          day: d?.value,
          times: d?.slots?.map((slt) => ({
            startTime: moment(new Date(slt?.startTime)).format("HH:mm"),
            endTime: moment(new Date(slt?.endTime)).format("HH:mm"),
          })),
          duration,
        };
      });
      if (data?.some((dt) => dt?.status)) {
        dispatch(updateClinicOpeningHours({ data })).then((res) => {
          if (res?.payload) {
            dispatch(getClinicOpeningHours());
            setEditEnabled(false);
          }
        });
      } else {
        setNoSlotErr(true);
      }
    } else {
      setTimeErr(true);
    }
  };

  const handleDaySelect = (ind, value) => {
    const reqValues = dayValues?.map((pd, i) => {
      if (i === ind) return { ...pd, isSelected: value };
      return pd;
    });
    setDayValues(reqValues);
    if (reqValues?.some((rv) => rv?.isSelected)) {
      setNoSlotErr(false);
    } else {
      setNoSlotErr(true);
    }
    if (
      validateTimeSlots(
        reqValues?.filter((rv) => rv?.isSelected),
        duration
      )
    ) {
      setTimeErr(false);
    } else {
      setTimeErr(true);
    }
  };

  const handleChangeDateValue = (name, value, mainInd, secInd) => {
    const reqValue = dayValues?.map((vd, i) => {
      if (i === mainInd) {
        return {
          ...vd,
          slots: vd?.slots?.map((slt, ind) => {
            if (ind === secInd) {
              return { ...slt, [name]: value };
            }
            return slt;
          }),
        };
      }
      return vd;
    });
    setDayValues(reqValue);
    if (
      validateTimeSlots(
        reqValue?.filter((rv) => rv?.isSelected),
        duration
      )
    ) {
      setTimeErr(false);
    } else {
      setTimeErr(true);
    }
  };

  const handleDeleteDateRow = (i, ind) => {
    const reqValue = dayValues?.map((vd, index) => {
      if (i === index) {
        return {
          ...vd,
          slots: vd?.slots?.filter((slt, index2) => index2 !== ind),
        };
      }
      return vd;
    });
    setDayValues(reqValue);
  };

  const handleAddDateRow = (i) => {
    const reqValue = dayValues?.map((vd, ind) => {
      if (i === ind) {
        return {
          ...vd,
          slots: [...vd?.slots, { ...initSlot, i: vd?.slots?.length }],
        };
      }
      return vd;
    });
    setDayValues(reqValue);
  };

  const handleAddHoliday = () => {
    if (holiday?.length === 0) {
      setHoliday([initialValues]);
    }
  };

  return (
    <BranchManagementMain active="consultation">
      <div className="consultation-white-con scroll-80vh">
        <div className="mb10 mtMin10">
          <Typography className="txt-semi-bold black fs14">
            Clinic schedule
          </Typography>
          <Grid container className="mt5">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="flex-row-ali-cen mt20">
                <div className="w5Per"></div>
                <div className="w15Per">
                  <Typography className="font-bold fs14 blue-color">
                    Day
                  </Typography>
                </div>
                <div className="w15Per">
                  <Typography className="font-bold fs14 blue-color">
                    Start Time
                  </Typography>
                </div>
                <div className="w15Per">
                  <Typography className="font-bold fs14 blue-color">
                    End Time
                  </Typography>
                </div>
                <div className="w5Per">
                  <CustomButton
                    text={isEditEnabled ? "Save" : "Edit"}
                    smallBtn
                    onClick={() => {
                      if (isEditEnabled) {
                        handleGlobalTimeSave();
                      } else {
                        setEditEnabled(!isEditEnabled);
                      }
                    }}
                  />
                </div>
              </div>
              <div className="box-hor-split mt5" />
            </Grid>
            <>
              {dayValues?.length > 0 ? (
                isEditEnabled ? (
                  dayValues?.map((pd, i) => (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      key={pd?.day + i}
                    >
                      <div className="flex-row ">
                        <div className="w5Per pl10">
                          <Checkbox
                            checked={pd?.isSelected}
                            onChange={(e) =>
                              handleDaySelect(i, !pd?.isSelected)
                            }
                          />
                        </div>
                        <div className="w15Per mt10">
                          <Typography className="txt-regular fs14 blue-color">
                            {pd?.day}
                          </Typography>
                        </div>

                        <div className="flex-coumn">
                          {pd?.slots?.length > 0 &&
                            pd?.slots?.map((slt, ind) => (
                              <div
                                className="flex-row-ali-cen mv3"
                                key={slt?.startTime + "time" + ind}
                              >
                                <div className="w25Per pr10">
                                  <CustomTextField
                                    name="startTime"
                                    fullWidth
                                    mobileTime
                                    handleChange={(e) =>
                                      handleChangeDateValue(
                                        "startTime",
                                        e?.target?.value,
                                        i,
                                        ind
                                      )
                                    }
                                    value={slt?.startTime}
                                    is12HourFomat={false}
                                  />
                                </div>
                                <div className="w25Per pr10">
                                  <CustomTextField
                                    name="endTime"
                                    fullWidth
                                    mobileTime
                                    handleChange={(e) =>
                                      handleChangeDateValue(
                                        "endTime",
                                        e?.target?.value,
                                        i,
                                        ind
                                      )
                                    }
                                    value={slt?.endTime}
                                    is12HourFomat={false}
                                  />
                                </div>
                                <div className="w10Per">
                                  <div className="flex-row">
                                    {pd?.slots?.length > 1 ? (
                                      <img
                                        src={require("../../../assets/images/png/minus.png")}
                                        alt=""
                                        className="h20 cursor"
                                        onClick={() =>
                                          handleDeleteDateRow(i, ind)
                                        }
                                      />
                                    ) : (
                                      <div className="h20 w20px" />
                                    )}
                                    {ind === 0 && (
                                      <img
                                        src={require("../../../assets/images/png/PlusIcon.png")}
                                        alt=""
                                        className="h20 cursor ml10"
                                        onClick={() => handleAddDateRow(i)}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                      <div className="box-hor-split w50Per" />
                    </Grid>
                  ))
                ) : (
                  dayValues?.length > 0 &&
                  dayValues?.map((itm, index) => (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      key={itm?.day + index}
                    >
                      <div className="flex-row mv5">
                        <div className="w5Per"></div>
                        <div className="w15Per h25 align-center-wh">
                          <Typography className="txt-regular fs14 blue-color capitalize">
                            {itm?.day}
                          </Typography>
                        </div>

                        <div className="flex-coumn w60Per">
                          {itm?.slots?.length > 0 &&
                            itm?.slots?.map((slt, ind) => (
                              <div
                                className="flex-row-ali-cen h25"
                                key={slt?.startTime + "time" + ind}
                              >
                                <div className="w25Per pr10">
                                  <Typography className="txt-regular fs14 black">
                                    {itm?.isSelected
                                      ? moment(new Date(slt?.startTime)).format(
                                          "HH:mm"
                                        )
                                      : ""}
                                  </Typography>
                                </div>
                                <div className="w25Per pr10">
                                  <Typography className="txt-regular fs14 black">
                                    {itm?.isSelected
                                      ? moment(new Date(slt?.endTime)).format(
                                          "HH:mm"
                                        )
                                      : ""}
                                  </Typography>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                      <div className="box-hor-split w50Per" />
                    </Grid>
                  ))
                )
              ) : (
                <div className="no-rec h100">No records available</div>
              )}

              {noSlotErr && (
                <FormHelperText error>
                  Please add atleast 1 record
                </FormHelperText>
              )}
              {timeErr && (
                <FormHelperText error>
                  Please provide valid time. End Time should be greater than the
                  Start Time
                </FormHelperText>
              )}

              {dayValues?.length > 0 && (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className="flex-row-ali-cen mt10">
                    <Typography className="txt-semi-bold black fs14">
                      Slot Duration:
                    </Typography>
                    <div className="ml10 w10Per">
                      <CustomTextField
                        name="duration"
                        fullWidth
                        value={duration}
                        handleChange={(e) => setDuration(e?.target?.value)}
                        endIcon
                        inputIcon="Mins"
                        disabled={!isEditEnabled}
                      />
                    </div>
                  </div>
                </Grid>
              )}
            </>
          </Grid>
        </div>

        <div className="mv10">
          <div className="flex-row mt20">
            <Typography className="txt-semi-bold black fs14">
              Holiday Calendar
            </Typography>
            <img
              src={require("../../../assets/images/png/PlusIcon.png")}
              alt=""
              className="h20 cursor ml20"
              onClick={handleAddHoliday}
            />
          </div>

          <div className="flex-row-ali-cen mt15">
            <div className="w20Per">
              <Typography className="w20Per font-bold fs14 blue-color">
                Date
              </Typography>
            </div>
            <div className="w20Per">
              <Typography className="w20Per font-bold fs14 blue-color">
                Occasion
              </Typography>
            </div>
          </div>
          <div className="box-hor-split" />

          {holidays?.length > 0 &&
            holidays?.map((hl, i) => (
              <div key={i}>
                <div className="flex-row-ali-cen mv10">
                  <div className="w20Per">
                    <Typography className="txt-regular fs14">
                      {moment(new Date(hl?.date)).format("MMMM DD")}
                    </Typography>
                  </div>
                  <div className="w20Per">
                    <Typography className="txt-regular fs14">
                      {hl?.holliday}
                    </Typography>
                  </div>
                  <div className="w5Per flex-center">
                    <div className="flex-end">
                      <img
                        src={require("../../../assets/images/png/del.png")}
                        alt=""
                        className="h20 cursor"
                        onClick={() => handleDelHoliday(hl?.holliId)}
                      />
                    </div>
                  </div>
                </div>

                <div className="box-hor-split w50Per" />
              </div>
            ))}

          {holiday?.length > 0 &&
            holiday?.map((hol) => (
              <>
                <div className="flex-row mv10">
                  <div className="w20Per pr10">
                    <CustomTextField
                      name="date"
                      fullWidth
                      mobileDate
                      handleChange={handleChageHolliday}
                      value={hol?.date}
                    />
                  </div>
                  <div className="w20Per pr10">
                    <CustomTextField
                      name="holliday"
                      fullWidth
                      handleChange={handleChageHolliday}
                      value={hol?.holliday}
                      error={occasionError}
                      helperText={
                        occasionError ? "Occasion is required field" : ""
                      }
                    />
                  </div>
                  <div className="w5Per flex-center h40">
                    <img
                      src={require("../../../assets/images/png/save.png")}
                      alt=""
                      className="h30 cursor"
                      onClick={handleSaveHoliday}
                    />
                  </div>
                </div>
                <div className="box-hor-split w50Per" />
              </>
            ))}

          {holiday?.length === 0 && (holidays?.length === 0 || !holidays) && (
            <div className="no-rec h100">No records available</div>
          )}
        </div>
      </div>
    </BranchManagementMain>
  );
};

export default ClinicConsultation;
