//
import Autocomplete from "./Autocomplete";
import Backdrop from "./Backdrop";
import Button from "./Button";
import Card from "./Card";
import Input from "./Input";
import Paper from "./Paper";
import Table from "./Table";
import Tooltip from "./Tooltip";
import Typography from "./Typography";

// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme) {
  return Object.assign(
    Card(theme),
    Table(theme),
    Input(theme),
    Paper(theme),
    Button(theme),
    Tooltip(theme),
    Backdrop(theme),
    Typography(theme),
    Autocomplete(theme),
    {
      MuiTextField: {
        styleOverrides: {
          root: {
            '& input:not([type="textarea"])': {
              height: "40px", // Set height to 40px for all inputs except textareas
              boxSizing: "border-box",
              padding: "0 14px", // Adjust padding to match the height
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            height: "40px", // Apply height for Select inputs
            "& .MuiSelect-select": {
              padding: "0 14px", // Adjust padding for select input
              display: "flex",
              alignItems: "center",
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          inputRoot: {
            height: "40px", // Apply height to Autocomplete inputs
            padding: "0 14px",
            boxSizing: "border-box",
          },
        },
      },
    }
  );
}
