import { Tooltip, Typography } from "@mui/material";

const Custom3dotString = ({
  str = "",
  length = 8,
  className = "font-bold fs14",
  capitalize = true,
}) => {
  const reqStr =
    str?.length > 0
      ? (capitalize ? str?.charAt(0)?.toUpperCase() : str?.charAt(0)) +
        str?.slice(1)
      : "";

  const getReqStr = () => {
    if (reqStr?.length > length) {
      return reqStr?.slice(0, length) + "...";
    } else {
      return reqStr;
    }
  };

  return (
    <Tooltip title={reqStr}>
      <Typography
        className={
          `whte-spa-no-wrap ${capitalize ? "capitalize" : ""} ` + className
        }
      >
        {getReqStr()}
      </Typography>
    </Tooltip>
  );
};

export default Custom3dotString;
