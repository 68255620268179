import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Card,
  Grid,
  IconButton,
  InputBase,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import Carousel from "better-react-carousel";
import Picker from "emoji-picker-react";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import SockJS from "sockjs-client/dist/sockjs";
import Stomp from "stompjs";
import Custom3dotString from "../../../../components/Custom3dotString";
import CustomModal from "../../../../components/CustomModal/CustomModal";
import {
  getAllMessagsByChatId,
  getAllMessagsByChatIdWitoutLoading,
  getChatList,
  getChatListWithoutLoading,
  uploadChatFilesByChatId,
} from "../../../../redux/reducers/chatSlice";
import { getClinicVets } from "../../../../redux/reducers/vetSlice";
import { AppColors } from "../../../../util/AppColors";
import { socketServer } from "../../../../util/server";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const Chat = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const stompClientRef = useRef(null);
  const messagesEndRef = useRef(null);
  const [msg, setMsg] = useState("");
  const [msgs, setMsgs] = useState([]);
  const [connectionPromise, setConnectionPromise] = useState(null);
  const [fleViewVsble, setFleViewVsble] = useState(false);
  const [files, setFiles] = useState(null);
  const [selectedChatId, setSelectedChatId] = useState(
    location?.state?.chatId ? location?.state?.chatId : null
  );
  const [emojiPickerVisible, setEmojiPickerVisible] = useState(false);
  const { list, messages } = useSelector((state) => state?.chat);
  const clinicVets = useSelector((state) => state?.vet?.vets);
  const user = localStorage.getItem("user");
  const role = localStorage.getItem("role");
  const profile = JSON.parse(user);
  const [vets, setVets] = useState([]);

  useEffect(() => {
    dispatch(getChatList());
    dispatch(getClinicVets());
  }, []);

  useEffect(() => {
    let mounted = true; // Ensure the component is mounted
    if (!selectedChatId || stompClientRef.current?.connected) return;

    const connectWebSocket = () => {
      return new Promise((resolve, reject) => {
        const stompClient = Stomp.over(new SockJS(socketServer));
        stompClientRef.current = stompClient;

        stompClient.connect(
          {},
          () => {
            if (mounted) {
              // Only subscribe if still mounted
              stompClient.subscribe(
                `/user/${selectedChatId}/topic/messages`,
                async (message) => {
                  const newMessage = JSON.parse(message.body);
                  setMsgs((prevMsgs) => [...prevMsgs, { ...newMessage }]);
                  // dispatch(getChatListWithoutLoading());
                }
              );
              stompClient.subscribe(
                `/user/${profile?.id}/topic/chatUser`,
                (message) => {
                  const parsedData = JSON.parse(message?.body);
                  if (selectedChatId === parsedData) {
                    //for handling read message count
                    dispatch(
                      getAllMessagsByChatIdWitoutLoading(selectedChatId)
                    ).then(async (res) => {
                      if (res?.payload) dispatch(getChatListWithoutLoading());
                    });
                  }
                }
              );
            }
            resolve();
          },
          (error) => {
            console.error("WebSocket connection error:", error);
            reject(error);
          }
        );
      });
    };

    const connectionPromise = connectWebSocket();
    setConnectionPromise(connectionPromise);

    return () => {
      mounted = false; // Set mounted to false on cleanup
      if (stompClientRef.current && stompClientRef.current.connected) {
        stompClientRef.current.disconnect();
      }
    };
  }, [selectedChatId, profile?.id]);

  useEffect(() => {
    if (!selectedChatId) return;
    dispatch(getAllMessagsByChatId(selectedChatId)).then(async (res) => {
      if (res?.payload) {
        const reqMsgs = res?.payload
          ?.map((msg) => ({
            ...msg,
            recipient: profile?.id === msg?.user?._id ? 3 : 1,
            userId: msg?.user?._id,
          }))
          ?.reverse();

        await setMsgs(reqMsgs ?? []);
        dispatch(getChatListWithoutLoading());
      }
    });
  }, [selectedChatId]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  }, [msgs]);

  useEffect(() => {
    const reqVets = clinicVets?.filter((vl) => {
      if (vl?.userType?.find((ut) => ut?.name === "ROLE_DOCTOR")) {
        return vl;
      }
    });
    setVets(reqVets);
  }, [clinicVets]);

  const handleUploadFile = (e) => {
    const form = new FormData();
    if (msg?.length > 0) form.append("message", msg);
    if (e?.target?.files?.length > 0) {
      for (let i = 0; i < e?.target?.files?.length; i++) {
        form.append("files", e?.target?.files?.[i]);
      }
    }

    dispatch(uploadChatFilesByChatId({ chatId: selectedChatId, form })).then(
      (res) => {
        if (res?.payload) {
          const lastMsg = {
            ...res?.payload,
            _id: res?.payload?.messageId,
            createdAt: res?.payload?.timeStamp,
            recipient:
              profile?.id === res?.payload?.user?._id || res?.payload?.userId
                ? 3
                : 1,
            userId: profile?.id,
            user: { _id: profile?.id, avatar: null, name: profile?.name },
          };
          if (res?.payload?.content?.length > 0) {
            lastMsg.text = res?.payload?.content;
          }
          setMsg("");
          setMsgs((prevMsgs) => [...prevMsgs, { ...lastMsg }]);
        }
      }
    );
  };

  const handleSend = async () => {
    if (msg?.length === 0 || msg?.trim() === "") return;
    if (!connectionPromise) {
      console.warn("WebSocket connection promise is not set.");
      return;
    }

    try {
      await connectionPromise;
    } catch (error) {
      console.error("WebSocket connection is not established yet.");
      return;
    }

    const newMessage = {
      userId: profile?.id,
      chatId: selectedChatId,
      _id: Math.round(Math.random() * 1000000),
      createdAt: new Date().toISOString(),
      user: { _id: profile?.id, name: profile?.name },
      text: msg,
    };

    const messageToSend = {
      ...newMessage,
      recipient: 3,
    };
    if (stompClientRef.current && stompClientRef.current.connected) {
      setMsg("");
      stompClientRef.current.send(
        "/app/sendMessage",
        {},
        JSON.stringify(messageToSend)
      );
    } else {
      console.warn("WebSocket is not connected.");
    }
  };

  const handleChat = (chatId) => {
    setMsgs([]);
    setMsg("");
    setSelectedChatId(chatId);
  };

  const onEmojiClick = (event, emojiObject) => {
    setMsg(msg + event?.emoji);
  };

  const handleClosePrevMod = () => {
    setFiles(null);
    setFleViewVsble(false);
  };

  const handlePrevFile = (list) => {
    setFiles(list);
    setFleViewVsble(true);
  };

  const getCurrentUserAndVetOrNot = (users) => {
    let user = null;
    const currUser = users?.[0]?.id === profile?.id;
    if (currUser) {
      user = { ...users?.[1] };
    } else {
      user = { ...users?.[0] };
    }
    const isVet = vets?.find((vet) => vet?.doctorId === user?.id);

    return {
      user,
      isVet: isVet ? true : false,
      isClinic: role === "ROLE_CLINIC",
    };
  };

  const getActiveChatDetails = () => {
    if (!list) return;
    const users = list?.find((li) => li?.chatId === selectedChatId)?.users;
    let user = null;
    const currUser = users?.[0]?.id === profile?.id;
    if (currUser) {
      user = { ...users?.[1] };
    } else {
      user = { ...users?.[0] };
    }
    const isVet = vets?.find((vet) => vet?.doctorId === user?.id);
    return {
      user,
      isVet: isVet ? true : false,
      isClinic: role === "ROLE_CLINIC",
    };
  };

  const getReqUnReadCount = (cl) => {
    return profile?.id === cl?.userId ? cl?.reqUserCount : cl?.userCount;
  };

  return (
    <>
      <div className="p20">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
            <Card
              sx={{
                borderColor: "#79C5EF61",
                padding: 2,
              }}
            >
              <Typography className="black8 font-bold fs18">Chat</Typography>

              {list?.length > 0 ? (
                <div
                  style={{
                    overflowY: "auto",
                    height: window.innerHeight - 145,
                  }}
                >
                  {list?.map((cl, i) => {
                    const reqDet = getCurrentUserAndVetOrNot(cl?.users);
                    const reqUser = reqDet?.user;
                    const isVet = reqDet?.isVet;

                    return (
                      <div
                        key={cl?.chatId}
                        onClick={() => handleChat(cl?.chatId)}
                        className="cursor"
                      >
                        <div className="flex-row-ali-cen mv15">
                          {reqUser?.userImage ? (
                            <img
                              src={reqUser?.userImage}
                              alt=""
                              className="chatImg"
                            />
                          ) : (
                            <div className="flex-center h50img">
                              <Typography className="font-bold fs30 white-color capitalize">
                                {reqUser?.name?.[0]}
                              </Typography>
                            </div>
                          )}
                          <div className="flex-column ml10">
                            <Typography className="fs14 black8 txt-semi-bold capitalize">
                              {`${isVet ? "Dr. " : ""}`}
                              {reqUser?.name}
                            </Typography>
                            <Custom3dotString
                              str={cl?.lastMessage}
                              className="txt-regular fs14 black8"
                              length={25}
                              capitalize={false}
                            />
                          </div>
                          <div className="flex1-end">
                            {getReqUnReadCount(cl) > 0 && (
                              <div className="flex-center">
                                <div className="not-count">
                                  <Typography className="white-color header fs8">
                                    {getReqUnReadCount(cl)}
                                  </Typography>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        {list?.length !== i + 1 && (
                          <div className="box-hor-split" />
                        )}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="no-rec h100">No records available</div>
              )}
            </Card>
          </Grid>
          {selectedChatId && (
            <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
              <Card
                sx={{
                  borderColor: "#79C5EF61",
                  padding: 2,
                  minHeight: 400,
                }}
              >
                <div className="flex-row-ali-cen">
                  {getActiveChatDetails()?.user?.userImage ? (
                    <img
                      className="chatLarImg"
                      alt=""
                      src={getActiveChatDetails()?.user?.userImage}
                    />
                  ) : (
                    <div className="flex-center h50img">
                      <Typography className="font-bold fs30 white-color capitalize">
                        {getActiveChatDetails()?.user?.name?.[0]}
                      </Typography>
                    </div>
                  )}

                  <div className="flex-column ml15">
                    <Typography className="txt-semi-bold fs26 black8 capitalize">
                      {getActiveChatDetails()?.isVet && "Dr "}
                      {getActiveChatDetails()?.user?.name}
                    </Typography>
                  </div>
                  <div className="flex1-end">
                    <MoreVertIcon sx={{ color: AppColors.gray5 }} />
                  </div>
                </div>
                <div className="box-hor-split mv10" />

                <div
                  style={{
                    overflowY: "auto",
                    height: window.innerHeight - 250,
                  }}
                  // className="chtScroll"
                  ref={messagesEndRef}
                >
                  {msgs?.map((ct, i) => {
                    const isClinicMsg = ct?.user?._id === profile?.id;

                    return (
                      <div key={ct?._id} className="mv10">
                        <div
                          className={`flex-row-ali-cen ${
                            isClinicMsg ? "flex1-end" : "flex-start"
                          }`}
                        >
                          {ct?.text?.length > 0 && !isClinicMsg && (
                            <div className="grayDot mr10" />
                          )}
                          {ct?.text?.length > 0 ? (
                            <Typography
                              className={isClinicMsg ? "msgVet" : "msgUser"}
                            >
                              {ct?.text}
                            </Typography>
                          ) : null}
                          {ct?.text?.length > 0 && isClinicMsg && (
                            <div className="blueDot ml10" />
                          )}
                        </div>
                        {ct?.files?.length > 0 && (
                          <div
                            className={`flex-row-ali-cen mt10 ${
                              isClinicMsg ? "flex1-end" : "flex-start"
                            }`}
                          >
                            {ct?.files?.map((fle, i) => {
                              const fileType = fle?.split(".").pop();
                              const isImg = ["png", "jpg", "jpeg"].includes(
                                fileType
                              );

                              return (
                                <div
                                  key={fle + i}
                                  className={i > 0 && "ml10"}
                                  onClick={() => handlePrevFile(ct?.files)}
                                >
                                  {isImg ? (
                                    <img
                                      src={fle}
                                      alt=""
                                      className="chat-img"
                                    />
                                  ) : (
                                    <iframe
                                      width="200"
                                      height="200"
                                      frameborder="0"
                                      src={`https://docs.google.com/gview?url=${fle}&embedded=true`}
                                    />
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        )}
                        <Typography
                          className={`fs12 gray14 txt-regular mt5 ${
                            isClinicMsg ? "vetMsgTime" : "usrMsgTime"
                          }`}
                        >
                          {moment(ct?.createdAt).format("dddd, h.mma")}
                        </Typography>
                      </div>
                    );
                  })}
                  <div ref={messagesEndRef} />
                </div>
                <div className="flex-row-ali-cen mv10">
                  <div className="w90Per">
                    <Paper
                      component="form"
                      sx={{
                        p: "2px 4px",
                        display: "flex",
                        alignItems: "center",
                        background: AppColors.lightBlue,
                        borderRadius: 20,
                        height: 40,
                      }}
                    >
                      <IconButton
                        sx={{ p: "5px" }}
                        component="label"
                        aria-label="attach"
                      >
                        <img
                          src={require("../../../../assets/images/png/attach.png")}
                          alt=""
                          className="imghw30"
                        />
                        <VisuallyHiddenInput
                          type="file"
                          onChange={handleUploadFile}
                          // accept="image/*"
                          accept=".png, .jpg, .jpeg, .pdf, .doc, .docx"
                          multiple
                        />
                      </IconButton>
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Type your message here..."
                        inputProps={{
                          "aria-label": "Type your message here...",
                        }}
                        value={msg}
                        onChange={(e) => setMsg(e?.target?.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleSend();
                            if (e.preventDefault()) return false;
                          }
                        }}
                      />
                      <IconButton type="button" aria-label="search">
                        <img
                          src={require("../../../../assets/images/png/emoji.png")}
                          alt=""
                          className="imghw30"
                          onClick={() =>
                            setEmojiPickerVisible(!emojiPickerVisible)
                          }
                        />
                      </IconButton>

                      {emojiPickerVisible && (
                        <div
                          style={{
                            position: "absolute",
                            bottom: 80,
                            right: 100,
                          }}
                        >
                          <Picker onEmojiClick={onEmojiClick} />
                        </div>
                      )}
                    </Paper>
                  </div>
                  <div className="w10Per flex-end">
                    <div className="sndBtnBack cursor" onClick={handleSend}>
                      <img
                        src={require("../../../../assets/images/png/sendIcon.png")}
                        alt=""
                        className="img-wh25"
                      />
                    </div>
                  </div>
                </div>
              </Card>
            </Grid>
          )}
        </Grid>
      </div>
      <CustomModal
        open={fleViewVsble}
        onClose={handleClosePrevMod}
        header=""
        modal
        modalWidth={50}
      >
        <Carousel cols={1} rows={1} gap={10} loop showDots>
          {files?.map((fle, i) => {
            const fileType = fle?.split(".").pop();
            const isImg = ["png", "jpg", "jpeg"].includes(fileType);

            return (
              <Carousel.Item>
                <div className="flex-center">
                  {isImg ? (
                    <img src={fle} alt="" className="chat-file-prev" />
                  ) : (
                    <iframe
                      className="chat-file-prev"
                      frameborder="0"
                      src={`https://docs.google.com/gview?url=${fle}&embedded=true`}
                    />
                  )}
                </div>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </CustomModal>
    </>
  );
};

export default Chat;
