import { FormHelperText, Grid, Typography } from "@mui/material";
import moment from "moment/moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import CustomButton from "../../../../components/CustomButton";
import CustomCheckbox from "../../../../components/CustomCheckbox";
import CustomModal from "../../../../components/CustomModal/CustomModal";
import Table from "../../../../components/CustomTable";
import CustomTextField from "../../../../components/CustomTextField";
import CustomUpload from "../../../../components/CustomUpload";
import {
  default as CustomSelect,
  default as Select,
} from "../../../../components/Select/Select";
import {
  getDewormingList,
  getFleaTreatmentList,
  getVaccinationList,
} from "../../../../redux/reducers/clinicSlice";
import {
  createPreventive,
  getFilterdPetPreventives,
  getPetPreventives,
  updatePreventive,
} from "../../../../redux/reducers/petSlice";
import { AppColors } from "../../../../util/AppColors";
import { preventiveTypes, statusList } from "../../../../util/arrayList";
import FilesWithCloseButton from "./FilesWithCloseButton";

const nameExpan = {
  type: "Select Type",
  name: "Vaccine Name",
  dueDate: "Due Date",
  remarks: "Remarks",
};

const initialValues = {
  type: "vaccination",
  name: "",
  dueDate: new Date(),
  remarks: "",
  files: [],
  price: 0,
  tax: 0,
};

const tableHeaders = [
  "type",
  "name",
  "date",
  "remark",
  // "file",
  "status",
  "preventiveImage",
];

const initialErrors = {
  type: false,
  name: false,
  dueDate: false,
  remarks: false,
};

const initialHelpers = {
  type: "",
  name: "",
  dueDate: "",
  remarks: "",
};

const initDownValues = {
  startDate: new Date(),
  endDate: new Date(),
};

const Preventive = ({ setScrollable }) => {
  const location = useLocation();
  const { appointment } = location.state || {};
  const dispatch = useDispatch();
  const contentToPrint = useRef(null);
  const [isEditModal, setEditModal] = useState(false);
  const [prevent, setPrevent] = useState(null);
  const [preventiveErrors, setPreventiveErrors] = useState(initialErrors);
  const [preventiveHelpers, setPreventiveHelpers] = useState(initialHelpers);
  const [preventiveValues, setPreventiveValues] = useState(initialValues);
  const [searchText, setSearchText] = useState("");
  const { preventives, filteredPreventives } = useSelector(
    (state) => state?.pet
  );
  const [type, setType] = useState("All");
  const [attachModVisible, setAttachModVisible] = useState(false);
  const [attachments, setAttachments] = useState(null);
  const [page, setPage] = useState(1);
  const itemsPerLoad = 10;
  const defaultUrl = `?page=1&itemSize=${itemsPerLoad}`;
  const [modVisible, setModVisible] = useState(false);
  const [vaccineList, setVaccineList] = useState([]);
  const [dewormingList, setDewormingList] = useState([]);
  const [fleaTreatmentList, setFleaTreatmentList] = useState([]);
  const [downloadModVsble, setDownloadModVsble] = useState(false);
  const [downloadTypes, setDownloadTypes] = useState([]);
  const [downloadValues, setDownloadValues] = useState(initDownValues);
  const [typeNotSelected, setTypeNotSelected] = useState(false);
  const [preViewModVsble, setPreViewModVsble] = useState(false);
  const [previewSingleData, setPreviewSingleData] = useState([]);
  const vaccines = useSelector((state) => state?.clinic?.vaccineList);
  const dewormings = useSelector((state) => state?.clinic?.dewormingList);
  const fleaTreatments = useSelector(
    (state) => state?.clinic?.fleaTreatmentList
  );
  const petData = useSelector(
    (state) => state?.pet?.complaintSummary?.data?.pet
  );
  const user = localStorage.getItem("user");
  const clinicProfile = JSON.parse(user);

  useEffect(() => {
    setScrollable(false);
    dispatch(
      getPetPreventives({
        petId: appointment?.appoinment?.petId,
        url: defaultUrl,
      })
    );
    dispatch(getVaccinationList());
    dispatch(getDewormingList());
    dispatch(getFleaTreatmentList());
  }, []);

  useEffect(() => {
    const vaccList = vaccines?.map((va) => ({
      ...va,
      value: va?.name,
    }));
    setVaccineList(vaccList);
    const dewormList = dewormings?.map((va) => ({
      ...va,
      value: va?.name,
    }));
    setDewormingList(dewormList);
    const fleaList = fleaTreatments?.map((va) => ({
      ...va,
      value: va?.name,
    }));
    setFleaTreatmentList(fleaList);
  }, [vaccines, dewormings, fleaTreatments]);

  const handleModClose = () => {
    setModVisible(!modVisible);
    setEditModal(false);
    setPreventiveValues(initialValues);
    setPreventiveErrors(initialErrors);
    setPreventiveHelpers(initialHelpers);
    setPrevent(null);
  };

  const handleEditModal = (prevent) => {
    setPrevent(prevent);
    const reqObj = {
      type: prevent?.type,
      name: prevent?.name,
      dueDate: new Date(prevent?.date),
      remarks: prevent?.remark,
      files: prevent?.file?.map((fle) => ({ file: null, previewUrl: fle })),
      price: 0,
      tax: 0,
    };
    const reqPre = (
      prevent?.type === "vaccination"
        ? vaccineList
        : prevent?.type === "deworming"
        ? dewormingList
        : prevent?.type === "fleaTreatment"
        ? fleaTreatmentList
        : []
    )?.find((li) => li?.name === prevent?.name);
    if (reqPre) {
      reqObj.price = reqPre?.sellPrice ?? reqPre?.servicePrice ?? 0;
      reqObj.tax = reqPre?.tax ?? 0;
      reqObj.serviceId = reqPre?.serviceId;
      reqObj.productId = reqPre?.productId;
    }

    setPreventiveValues(reqObj);
    setModVisible(true);
    setEditModal(true);
  };

  const handleChangeValues = (name, value, addObj) => {
    const reqObj = { ...preventiveValues, [name]: value };
    if (name === "type") reqObj.name = null;
    if (name === "name" && addObj) {
      reqObj.price = addObj?.price;
      reqObj.tax = addObj?.tax;
    }
    if (addObj?.productId) reqObj.productId = addObj?.productId;
    if (addObj?.serviceId) reqObj.serviceId = addObj?.serviceId;
    setPreventiveValues(reqObj);
    setPreventiveErrors({
      ...preventiveErrors,
      [name]: value?.length > 0 || typeof value === "number" ? false : true,
    });
    setPreventiveHelpers({
      ...preventiveHelpers,
      [name]:
        value?.length > 0 || typeof value === "number"
          ? ""
          : name === "name"
          ? `${
              preventiveValues?.type === "vaccination"
                ? "Vaccination"
                : preventiveValues?.type === "deworming"
                ? " Deworming"
                : "Flea"
            } name is required`
          : `${nameExpan?.[name]} is required`,
    });
  };

  const onUploadFile = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setPreventiveValues({
        ...preventiveValues,
        files: [
          // ...preventiveValues?.files,
          {
            file: e.target.files[0],
            previewUrl: reader.result,
          },
        ],
      });
    };
    reader.readAsDataURL(file);
  };

  const handleImageRemove = (ind) => {
    const reqFiles = preventiveValues?.files?.filter((fl, i) => i !== ind);
    setPreventiveValues({ ...preventiveValues, files: reqFiles });
  };

  const handleSave = (status) => {
    const err = {};
    const hlp = {};
    if (!preventiveValues?.type) {
      err.type = true;
      hlp.type = "Type is required";
    }
    if (preventiveValues?.name === "" || preventiveValues?.name === null) {
      err.name = true;
      hlp.name = `${
        preventiveValues?.type === "vaccination"
          ? "Vaccination"
          : preventiveValues?.type === "deworming"
          ? " Deworming"
          : "Flea"
      } name is required`;
    }
    if (!preventiveValues?.dueDate) {
      err.dueDate = true;
      hlp.dueDate = "Due Date is required";
    }

    if (Object.keys(err).length > 0) {
      setPreventiveErrors({ ...preventiveErrors, ...err });
      setPreventiveHelpers({ ...preventiveHelpers, ...hlp });
      return;
    }

    const appointmentId = appointment?.appoinment?.appoimentId;
    const form = new FormData();
    if (isEditModal) {
      form.append("appId", appointmentId);
    }
    form.append("type", preventiveValues?.type);
    form.append("name", preventiveValues?.name);
    form.append("price", Number(preventiveValues?.price ?? 0));
    form.append("tax", Number(preventiveValues?.tax ?? 0));
    form.append(
      "date",
      moment(new Date(preventiveValues?.dueDate)).format("YYYY-MM-DD")
    );
    form.append("remark", preventiveValues?.remarks);
    form.append("status", status);
    form.append("inpatient", false);
    if (preventiveValues?.productId) {
      form.append("productId", preventiveValues?.productId);
    }
    if (preventiveValues?.serviceId) {
      form.append("serviceId", preventiveValues?.serviceId);
    }
    if (prevent?.preventiveId) form.append("detailId", prevent?.preventiveId);

    if (
      preventiveValues?.type === "vaccination" &&
      preventiveValues?.files?.length > 0
    ) {
      preventiveValues?.files?.forEach((fle) => {
        form.append("file", fle?.file ?? fle?.previewUrl);
      });
    }
    if (!isEditModal) {
      dispatch(createPreventive({ appointmentId, form })).then((res) => {
        if (res?.payload?.status === 200) {
          dispatch(
            getPetPreventives({
              petId: appointment?.appoinment?.petId,
              url: defaultUrl,
            })
          );
          setPage(1);
          handleModClose();
          setPreventiveValues(initialValues);
        }
      });
    } else {
      const metaData = { preventId: prevent?.preventiveId, form };
      dispatch(updatePreventive(metaData)).then((res) => {
        if (res?.payload?.status === 200) {
          dispatch(
            getPetPreventives({
              petId: appointment?.appoinment?.petId,
              url: defaultUrl,
            })
          );
          setPage(1);
          handleModClose();
          setPreventiveValues(initialValues);
        }
      });
    }
  };

  const handleChangePage = (e, selectedPage) => {
    dispatch(
      getPetPreventives({
        petId: appointment?.appoinment?.petId,
        url: `?page=${selectedPage}&itemSize=${itemsPerLoad}`,
      })
    );
    setPage(selectedPage);
  };

  const onDownloadModClose = () => {
    setDownloadModVsble(false);
    setDownloadTypes([]);
    setDownloadValues(initDownValues);
    setTypeNotSelected(false);
  };

  const handleChangeCheckboxValue = (name, value) => {
    const prevType = downloadTypes?.find((typ) => typ === name);
    if (prevType) {
      const reqList = downloadTypes?.filter((typ) => typ !== name);
      setDownloadTypes(reqList);
      setTypeNotSelected(reqList?.length > 0 ? false : true);
    } else {
      setDownloadTypes([...downloadTypes, name]);
      setTypeNotSelected(false);
    }
  };

  const handleChangeDownValues = (e) => {
    const { name, value } = e?.target;
    setDownloadValues({ ...downloadValues, [name]: value });
  };

  const handlePreview = () => {
    if (downloadTypes?.length === 0) return setTypeNotSelected(true);
    const url = `?filter=${downloadTypes?.toString()}&startDate=${moment(
      new Date(downloadValues?.startDate)
    ).format("YYYY-MM-DD")}&endDate=${moment(
      new Date(downloadValues?.endDate)
    ).format("YYYY-MM-DD")}`;
    dispatch(
      getFilterdPetPreventives({
        petId: appointment?.appoinment?.petId,
        url,
      })
    );
    setPreViewModVsble(true);
  };

  const onClosePreViewMod = () => {
    setPreViewModVsble(false);
    setDownloadTypes([]);
    setPreviewSingleData([]);
  };

  const getPreventiveByType = (typ) => {
    return previewSingleData?.length > 0
      ? previewSingleData?.filter((pre) => pre?.type === typ)
      : filteredPreventives?.data?.filter(
          (pre) => pre?.type === typ && pre?.status === "Completed"
        );
  };

  const renderRecord = (list, typ) => {
    return list?.map((li, i) => {
      const reqPrd = (
        li?.type === "vaccination"
          ? vaccineList
          : li?.type === "deworming"
          ? dewormingList
          : li?.type === "fleaTreatment"
          ? fleaTreatmentList
          : []
      )?.find((prd) => prd?.name === li?.name);

      return (
        <div className="gray-back-con1 mv5" key={Date.now() + i}>
          <div className="flex-row-ali-cen flex-row-wrap">
            <Typography className="txt-semi-bold black fs12">
              Product Name: {li?.name}
            </Typography>
            <div className="gray-dot2 mh5" />

            <Typography className="font-medium black4 fs12">
              {typ === "vaccination" ? "Vaccine:" : "Trade Name:"}
            </Typography>

            <Typography className="txt-regular black4 fs12 ml5">
              {reqPrd?.tradeName ?? ""}
            </Typography>
            <div className="gray-dot2 mh5" />
            <Typography className="font-medium black4 fs12">
              Manufacturer:
            </Typography>
            <Typography className="txt-regular black4 fs12 ml5">
              {reqPrd?.brand ?? ""}
            </Typography>
            <div className="gray-dot2 mh5" />
            <Typography className="font-medium black4 fs12">
              Batch No:
            </Typography>
            <Typography className="txt-regular black4 fs12 ml5">
              {reqPrd?.batchNumber ?? ""}
            </Typography>
          </div>
          <div className="flex-row-ali-cen mt5 flex-row-wrap">
            <div className="gray-dot2 mr5" />
            <Typography className="font-medium black4 fs12">
              Administered date & by:
            </Typography>
            <div className="flex-row">
              <Typography className="txt-regular black4 fs12 ml5 capitalize">
                {moment(new Date(li?.date)).format("DD MMM YYYY")}
              </Typography>
              {li?.doctorName?.length > 0 ? (
                <div className="flex-row">
                  <div className="txt-regular black4 fs12 ml5 capitalize">
                    | Dr {li?.doctorName}
                  </div>
                  {li?.registrationNo?.length > 0 ? (
                    <div className="flex-row-ali-cen">
                      <div className="font-regular fs12 black ml5">(</div>
                      <div className="font-medium black4 fs12 ml2">RegNo:</div>
                      <div className="font-regular fs12 black ml5 capitalize">
                        {li?.registrationNo} )
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      );
    });
  };

  const handlePrint = useReactToPrint({
    documentTitle: `Preventive History-${petData?.petName}-${
      previewSingleData?.length > 0
        ? `${moment(previewSingleData?.[0]?.date).format("DD MMM YYYY")}`
        : `${moment(new Date(downloadValues?.startDate)).format(
            "DD MMM YYYY"
          )}-${moment(new Date(downloadValues?.endDate)).format("DD MMM YYYY")}`
    }`,
    removeAfterPrint: true,
  });

  // const handleDownloadPreventive = () => {
  //   const url = `${baseURL}/export/preventive?petId=${
  //     appointment?.appoinment?.petId
  //   }&filter=${downloadTypes?.toString()}&startDate=${moment(
  //     new Date(downloadValues?.startDate)
  //   ).format("YYYY-MM-DD")}&endDate=${moment(
  //     new Date(downloadValues?.endDate)
  //   ).format("YYYY-MM-DD")}`;
  //   window.open(url, "_blank");
  // };

  const handleViewPreventive = (data) => {
    setPreviewSingleData([data]);
    handleChangeCheckboxValue(data?.type);
    setPreViewModVsble(true);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className="flex-row ml20 mt20">
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="ml70">
              <CustomTextField
                search
                placeholder="Search"
                fullWidth
                backgroundColor={AppColors.lightPink}
                value={searchText}
                handleChange={(e) => setSearchText(e.target.value)}
              />
            </Grid>
            <div className="w20Per ml20">
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <div className="flex-row-ali-cen">
                  <div className="normal-height">
                    <CustomButton
                      text="Add"
                      smallBtn
                      onClick={() => setModVisible(true)}
                    />
                  </div>

                  <img
                    src={
                      require("../../../../assets/images/svg/download.svg")
                        .default
                    }
                    alt=""
                    className="cursor ml20 w30h30"
                    onClick={() => setDownloadModVsble(true)}
                  />
                </div>
              </Grid>
            </div>
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
              <CustomSelect
                select
                list={statusList}
                value={type}
                handleChange={(e) => setType(e?.target?.value)}
              />
            </Grid>
          </div>
        </Grid>
        <div className="pb100 w100Per">
          <div className="mh16Min">
            <Table
              columns={tableHeaders}
              datas={preventives?.data
                ?.map((item) => ({
                  ...item,
                  date: moment(item?.date).format("DD MMM YYYY"),
                }))
                .flat()
                ?.filter((pre) =>
                  type === "All" ? pre?.status : pre?.status === type
                )
                ?.filter(
                  (pre) =>
                    pre?.type
                      ?.toLowerCase()
                      .includes(searchText?.toLowerCase()) ||
                    pre?.name?.toLowerCase().includes(searchText?.toLowerCase())
                )}
              grey
              preventive
              onEdit={(e) => handleEditModal(e)}
              onClickFile={(e) => {
                if (e?.file?.length > 0) {
                  setAttachments(e?.file ?? []);
                  setAttachModVisible(true);
                }
              }}
              onClickViewBtn={(e) => handleViewPreventive(e)}
              page={page}
              rowsPerPage={itemsPerLoad}
              totalRecords={preventives?.totalRecords ?? 0}
              handleChangePage={handleChangePage}
            />
          </div>
        </div>
      </Grid>

      <CustomModal
        open={downloadModVsble}
        onClose={onDownloadModClose}
        header="Download"
        rightModal
        modalWidth={30}
      >
        <Grid container spacing={1} className="ph20">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography className="gray7 txt-semi-bold">Type: </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <CustomCheckbox
              label="Vaccination"
              checked={downloadTypes?.includes("vaccination")}
              onChange={(e) => handleChangeCheckboxValue("vaccination", e)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <CustomCheckbox
              label="Flea Treatment"
              checked={downloadTypes?.includes("fleaTreatment")}
              onChange={(e) => handleChangeCheckboxValue("fleaTreatment", e)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <CustomCheckbox
              label="Deworming"
              checked={downloadTypes?.includes("deworming")}
              onChange={(e) => handleChangeCheckboxValue("deworming", e)}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="mt10">
            <CustomTextField
              name="startDate"
              label="Start Date"
              fullWidth
              handleChange={handleChangeDownValues}
              value={downloadValues?.startDate}
              mobileDate
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="mt10">
            <CustomTextField
              name="endDate"
              label="End Date"
              fullWidth
              handleChange={handleChangeDownValues}
              value={downloadValues?.endDate}
              mobileDate
            />
          </Grid>
          {typeNotSelected && (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <FormHelperText error>
                Please select at lease 1 type to proceed
              </FormHelperText>
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="flex1-end mt20">
              <div className="w30Per">
                <CustomButton text="Preview" smallBtn onClick={handlePreview} />
              </div>
            </div>
          </Grid>
        </Grid>
      </CustomModal>

      <CustomModal
        open={preViewModVsble}
        onClose={onClosePreViewMod}
        header=""
        modal
        modalWidth={60}
      >
        <div className="ph10 scroll-70vh">
          <div ref={contentToPrint} className="p20">
            <div className="flex-row-ali-cen">
              <div className="w15Per">
                <img
                  src={clinicProfile?.image}
                  alt=""
                  className="img-h50 ml10"
                />
              </div>
              <div className="w70Per flex-center">
                <div className="flex-column">
                  <Typography className="flex-center blue-color font-bold fs18">
                    Preventive History
                  </Typography>
                  <Typography className="txt-regular black fs14 mt10 flex-center">
                    {previewSingleData?.length > 0
                      ? `${moment(previewSingleData?.[0]?.date).format(
                          "DD MMM YYYY"
                        )}`
                      : `${moment(new Date(downloadValues?.startDate)).format(
                          "DD MMM YYYY"
                        )} - ${moment(new Date(downloadValues?.endDate)).format(
                          "DD MMM YYYY"
                        )}`}
                  </Typography>
                </div>
              </div>
              <div className="w15Per">
                <div className="flex-end">
                  <div className="blu-back wh50 flex-center">
                    <img
                      src={require("../../../../assets/images/png/VetInstantLogo.png")}
                      alt=""
                      className="img-hw40"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="br4brblue mt20">
              <div className="p10">
                <div className="flex-row-ali-cen">
                  <div className="w33Per">
                    <div className="flex-row-ali-cen">
                      <Typography className="blue-color font-bold fs14 capitalize">
                        Pet Name:
                      </Typography>
                      <Typography className="txt-regular black fs14 ml5">
                        {petData?.petName}
                      </Typography>
                    </div>
                    <div className="flex-row-ali-cen">
                      <Typography className="blue-color font-bold fs14">
                        Breed:
                      </Typography>
                      <Typography className="txt-regular black fs14 ml5 capitalize">
                        {petData?.breed}
                      </Typography>
                    </div>
                  </div>
                  <div className="w33Per">
                    <div className="flex-row-ali-cen">
                      <Typography className="blue-color font-bold fs14">
                        Gender:
                      </Typography>
                      <Typography className="txt-regular black fs14 ml5 capitalize">
                        {petData?.gender}
                      </Typography>
                    </div>
                    <div className="flex-row-ali-cen">
                      <Typography className="blue-color font-bold fs14">
                        DOB:
                      </Typography>
                      <Typography className="txt-regular black fs14 ml5">
                        {moment(petData?.dob).format("DD MMM YYYY")}
                      </Typography>
                    </div>
                  </div>
                  <div className="w33Per">
                    <div className="flex-row-ali-cen">
                      <Typography className="blue-color font-bold fs14 capitalize">
                        Parent Name:
                      </Typography>
                      <Typography className="txt-regular black fs14 ml5">
                        {petData?.userName}
                      </Typography>
                    </div>
                    <div className="flex-row-ali-cen">
                      <Typography className="blue-color font-bold fs14">
                        Contact:
                      </Typography>
                      <Typography className="txt-regular black fs14 ml5">
                        {petData?.userMobile}
                      </Typography>
                    </div>
                  </div>
                </div>
                <div className="ph10 mv10 blue-hor-split" />
                {downloadTypes?.includes("vaccination") && (
                  <>
                    <Typography className="font-bold blue-color fs14 mv5">
                      Vaccination
                    </Typography>
                    {getPreventiveByType("vaccination")?.length > 0 ? (
                      renderRecord(
                        getPreventiveByType("vaccination"),
                        "vaccination"
                      )
                    ) : (
                      <div className="no-rec">No records available</div>
                    )}
                  </>
                )}
                {downloadTypes?.includes("deworming") && (
                  <>
                    <Typography className="font-bold blue-color fs14 mv5">
                      Deworming
                    </Typography>
                    {getPreventiveByType("deworming")?.length > 0 ? (
                      renderRecord(
                        getPreventiveByType("deworming"),
                        "deworming"
                      )
                    ) : (
                      <div className="no-rec">No records available</div>
                    )}
                  </>
                )}
                {downloadTypes?.includes("fleaTreatment") && (
                  <>
                    <Typography className="font-bold blue-color fs14 mv5">
                      Flea Treatment
                    </Typography>
                    {getPreventiveByType("fleaTreatment")?.length > 0 ? (
                      renderRecord(
                        getPreventiveByType("fleaTreatment"),
                        "fleaTreatment"
                      )
                    ) : (
                      <div className="no-rec">No records available</div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="flex-center mv20">
            <div className="w20Per">
              <CustomButton
                text="Download"
                onClick={() => handlePrint(null, () => contentToPrint.current)}
                // onClick={handleDownloadPreventive}
                smallBtn
              />
            </div>
          </div>
        </div>
      </CustomModal>

      <CustomModal
        open={modVisible}
        onClose={handleModClose}
        header={isEditModal ? "Edit" : "Add"}
        rightModal
        modalWidth={30}
      >
        <div className="scroll-80vh">
          <Grid container spacing={2} className="ph20 mt10">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Select
                list={preventiveTypes}
                value={preventiveValues?.type}
                name="type"
                label={nameExpan?.["type"]}
                select
                mandatoryField
                handleChange={(e) =>
                  handleChangeValues("type", e?.target?.value)
                }
                error={preventiveErrors?.type}
                helperText={preventiveHelpers?.type}
                disabled={isEditModal}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Select
                list={
                  preventiveValues?.type === "vaccination"
                    ? vaccineList
                    : preventiveValues?.type === "deworming"
                    ? dewormingList
                    : preventiveValues?.type === "fleaTreatment"
                    ? fleaTreatmentList
                    : []
                }
                value={preventiveValues?.name ? preventiveValues?.name : null}
                name="name"
                label={
                  preventiveValues?.type === "vaccination"
                    ? "Vaccine Name"
                    : preventiveValues?.type === "deworming"
                    ? "Deworming Name"
                    : "Treatment Name"
                }
                mandatoryField
                newSelect
                handleChange={(e) => {
                  handleChangeValues("name", e?.value, {
                    price: e?.sellPrice ?? 0,
                    tax: e?.tax ?? 0,
                    productId: e?.productId ?? null,
                    serviceId: e?.serviceId ?? null,
                  });
                }}
                error={preventiveErrors?.name}
                helperText={preventiveHelpers?.name}
                disabled={isEditModal}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <CustomTextField
                name="dueDate"
                label={nameExpan?.["dueDate"]}
                fullWidth
                handleChange={(e) =>
                  handleChangeValues("dueDate", e?.target?.value)
                }
                value={preventiveValues?.dueDate}
                mobileDate
                mandatoryField
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <CustomTextField
                label={nameExpan?.["remarks"]}
                placeholder="Remarks"
                name="remarks"
                fullWidth
                handleChange={(e) =>
                  handleChangeValues("remarks", e?.target?.value)
                }
                value={preventiveValues?.remarks}
                multiline
                error={preventiveErrors?.remarks}
                helperText={preventiveHelpers?.remarks}
              />
            </Grid>

            {preventiveValues?.type === "vaccination" ? (
              preventiveValues?.files?.length > 0 ? (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <FilesWithCloseButton
                    files={preventiveValues?.files}
                    onClickFile={(i, file) => handleImageRemove(i)}
                  />
                </Grid>
              ) : null
            ) : null}
            {preventiveValues?.type === "vaccination" ? (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <CustomUpload onUploadFile={onUploadFile} multipleNew />
              </Grid>
            ) : null}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="flex1-center">
                {!prevent?.preventiveId ? (
                  <div>
                    <CustomButton
                      text="Schedule"
                      smallBtn
                      onClick={() => handleSave("Pending")}
                    />
                  </div>
                ) : null}
                <div className="ml10">
                  <CustomButton
                    text="Mark as complete"
                    onClick={() => handleSave("Completed")}
                    tabSelectdBtn
                    noBgbtn
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </CustomModal>
    </>
  );
};

export default Preventive;
