import NavigateBeforeOutlinedIcon from "@mui/icons-material/NavigateBeforeOutlined";
import NavigateNextOutlinedIcon from "@mui/icons-material/NavigateNextOutlined";
import { Grid, Typography } from "@mui/material";
import { groupBy } from "lodash";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getVetAppointmentsByIdAndFilter } from "../../../redux/reducers/clinicSlice";
import { storeVetCalScreenDetails } from "../../../redux/reducers/mixedSlice";
import {
  createTimeSlots,
  getDateList,
  getHourRanges,
  isTimeBetween,
} from "../../../util/function";
import VetAndUpcomingAppointments from "../../CommonScreens/VetAndUpcomingAppointments/VetAndUpcomingAppointments";
import VetBookAppointment from "./VetBookAppointment";

const VetCalendar = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState("today");
  const [timeList, setTimeList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const vetAppointments = useSelector(
    (state) => state?.clinic?.vetAppointments
  );
  const [dateList, setDateList] = useState([]);
  const [reqApnts, setReqApnts] = useState(null);
  const [modalBookVisible, setModalBookVisible] = useState(false);
  const vetDetails = useSelector((state) => state?.clinic?.vet);
  const user = localStorage.getItem("user");
  const profile = JSON.parse(user);
  const timeRef = useRef(null);

  useEffect(() => {
    const reqTimes = createTimeSlots("00:00", "23:59", 60);
    setTimeList(reqTimes);
    getDateLists();
  }, []);

  useEffect(() => {
    if (timeRef?.current) {
      timeRef?.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [timeList]);

  useEffect(() => {
    getReqAppointments();
  }, [vetAppointments]);

  useEffect(() => {
    dispatch(
      getVetAppointmentsByIdAndFilter(
        `${location?.state?.vet?.doctorId ?? profile?.id}?type=${selectedType}${
          selectedType === "date"
            ? `&date=${moment(selectedDate).format("YYYY-MM-DD")}`
            : ""
        }`
      )
    );
    //for storing details to call socket
    dispatch(
      storeVetCalScreenDetails({
        selectedType,
        userId: profile?.id,
        selectedDate,
      })
    );
  }, [selectedType, selectedDate]);

  const getReqAppointments = () => {
    const reqList = [];
    timeList?.forEach((tm) => {
      const slots = vetAppointments?.filter((ap) =>
        isTimeBetween(ap?.appoimentTime, tm)
      );
      slots?.reverse()?.forEach((sl) => {
        const timeBet = isTimeBetween(sl?.appoimentTime, tm);
        if (timeBet) {
          if (sl?.serviceType === "Surgery") {
            const hrRange = getHourRanges(sl?.surgeryTimes);
            if (hrRange?.length > 0) {
              hrRange?.forEach((hr) => {
                reqList.push({ ...sl, time: hr });
              });
            }
          } else {
            reqList.push({ ...sl, time: `${tm}-${tm?.split(":")?.[0]}:59` });
          }
        }
      });
    });
    setReqApnts(groupBy(reqList, "time"));
  };

  const getDateLists = async () => {
    const dates = await getDateList();
    setDateList(dates);
  };

  const handleDateChange = (value) => {
    const reqDate = new Date(selectedDate);
    reqDate.setDate(
      value === "add" ? reqDate.getDate() + 1 : reqDate.getDate() - 1
    );
    setSelectedDate(reqDate);
  };

  const checkSameDay = () => {
    return new Date().getDate() !== new Date(selectedDate).getDate();
  };

  const navDetailsPage = (det) => {
    navigate(`/clinic-pet-details`, {
      state: { appointment: { appoinment: det } },
    });
  };

  return (
    <VetAndUpcomingAppointments
      vetId={location?.state?.vet?.doctorId ?? profile?.id}
      active="vetCalendar"
    >
      <Grid container className="ph2">
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="back-white p10"
        >
          <div className="flex-row">
            <div className="flex-start">
              <div className="flex-center">
                <Typography
                  className={`font-bold fs14 cursor day-type-box ${
                    selectedType === "today" ? "black" : "gray2"
                  }`}
                  onClick={() => setSelectedType("today")}
                >
                  Today
                </Typography>
              </div>
            </div>
            <div className="flex1-center">
              <div className="flex-row">
                <div className="flex-center">
                  <NavigateBeforeOutlinedIcon
                    className={`calendar-arrow ${
                      selectedType === "date" && checkSameDay()
                        ? "cursor"
                        : "no-cursor"
                    }`}
                    onClick={() => handleDateChange("minus")}
                  />
                  <Typography className="text-bold mh20">
                    {selectedType === "today"
                      ? moment(new Date()).format("DD MMM YYYY")
                      : selectedType === "date"
                      ? moment(new Date(selectedDate)).format("DD MMM YYYY")
                      : `${moment(new Date()).format("DD MMM")} - ${moment(
                          new Date(new Date().setDate(new Date().getDate() + 6))
                        ).format("DD YYYY")}`}
                  </Typography>
                  <NavigateNextOutlinedIcon
                    className={`calendar-arrow ${
                      selectedType === "date" ? "cursor" : "no-cursor"
                    }`}
                    onClick={() => handleDateChange("add")}
                  />
                </div>
              </div>
            </div>
            <div className="w20Per">
              <div className="flex-center">
                <Typography
                  className={`font-bold fs14 cursor day-type-box ${
                    selectedType === "date" ? "black" : "gray2"
                  }`}
                  onClick={() => setSelectedType("date")}
                >
                  Day
                </Typography>
                <Typography
                  className={`font-bold fs14 cursor day-type-box ${
                    selectedType === "week" ? "black" : "gray2"
                  }`}
                  onClick={() => setSelectedType("week")}
                >
                  Week
                </Typography>
              </div>
            </div>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="mt3 back-white card-columns"
        >
          <div
            className={`${
              selectedType !== "week" ? "w100Per" : "scrollx w160Per"
            }`}
          >
            {selectedType === "week" ? (
              <>
                <div className="flex-row h70AliCen">
                  <div className="w10Per" />

                  <div className="w1Per">
                    <div className="flex-center">
                      <div className="box-ver-split" />
                    </div>
                  </div>
                  {dateList?.length > 0 &&
                    dateList?.map((dt, i) => (
                      <>
                        <div
                          className="w20Per ph5 pv2"
                          key={i + dt?.name + "docname"}
                        >
                          <div className="flex-center">
                            <Typography className="text-bold">
                              {moment(dt).format("ddd DD")}
                            </Typography>
                          </div>
                        </div>
                        <div className="w1Per" key={i + dt?.name + "verbar"}>
                          <div className="flex-center">
                            <div className="box-ver-split" />
                          </div>
                        </div>
                      </>
                    ))}
                </div>
                <div className="box-hor-split" />
              </>
            ) : null}

            {timeList?.length > 0 &&
              timeList?.map((tm, i) => {
                const timeMatch = reqApnts?.[`${tm}-${tm?.split(":")?.[0]}:59`];
                const currTime = moment().format("HH:mm");
                const timeIsBet = isTimeBetween(currTime, tm);

                return (
                  <div key={i} ref={timeIsBet ? timeRef : null}>
                    {selectedType !== "week" ? (
                      <div className="flex-row h70AliCen">
                        <div className="w10Per">
                          <div
                            className="flex-center cursor"
                            onClick={() => setModalBookVisible(true)}
                          >
                            <Typography
                              className={`font-bold fs12 ${
                                timeIsBet ? "red-color" : "gray6"
                              }`}
                            >
                              {tm}
                            </Typography>
                          </div>
                        </div>
                        <div className="w1Per">
                          <div className="flex-center">
                            <div className="box-ver-split" />
                          </div>
                        </div>
                        <div className="w89Per ph5 pv2">
                          <div
                            className={`flex-row ${
                              timeMatch?.length > 1 ? "jus-con-spa-bet" : ""
                            }`}
                          >
                            {timeMatch?.slice(0, 4)?.map((timMa, index) => (
                              <div
                                className={`cursor 
                                ${
                                  timeMatch?.length === 1
                                    ? "w100Per"
                                    : timeMatch?.length === 2
                                    ? "w49Per"
                                    : timeMatch?.length === 3
                                    ? "w32Per"
                                    : "w23Per"
                                }`}
                                key={index + timMa?.appoimentTime}
                                onClick={() => navDetailsPage(timMa)}
                              >
                                <div
                                  className={
                                    timMa?.serviceType === "Surgery"
                                      ? "cal-gray-box"
                                      : timMa?.serviceType === "Vaccination"
                                      ? "cal-green-box"
                                      : timMa?.serviceType === "Consultation"
                                      ? "cal-blue-box"
                                      : timMa?.serviceType === "Emergency"
                                      ? "cal-red-box"
                                      : "cal-rose-box"
                                  }
                                >
                                  <div className="flex-row">
                                    <div className="flex-column jus-con-spa-bet h50">
                                      <div className="flex-row">
                                        {timMa?.petImage ? (
                                          <img
                                            src={timMa?.petImage}
                                            alt=""
                                            className="pet-card-img2"
                                          />
                                        ) : (
                                          <div className="flex-center h20img">
                                            <Typography className="font-bold fs30 white-color capitalize">
                                              {timMa?.petName?.[0]}
                                            </Typography>
                                          </div>
                                        )}
                                        <div
                                          className={`ml10 txt-regular white-color fs8 card-consultation ${
                                            timMa?.appoinmentType === "Physical"
                                              ? "card-con-blue-back"
                                              : timMa?.appoinmentType ===
                                                "Virtual"
                                              ? "virtual-bg-color"
                                              : "card-con-gray-back"
                                          }`}
                                        >
                                          {timMa?.appoinmentType}
                                        </div>
                                        <Typography
                                          className={`ml5 font-bold fs8 cal-time ${
                                            timMa?.serviceType === "Vaccination"
                                              ? "green-back"
                                              : timMa?.serviceType ===
                                                "Consultation"
                                              ? "card-bot-blue-back"
                                              : timMa?.serviceType ===
                                                "Emergency"
                                              ? "card-bot-red-back"
                                              : "card-bot-rose-back"
                                          }`}
                                        >
                                          {timMa?.appoimentTime}
                                        </Typography>
                                      </div>
                                      <div className="flex-row">
                                        <Typography className="font-bold fs10 capitalize">
                                          {`${timMa?.serviceType ?? ""} | ${
                                            timMa?.petName
                                          }`}
                                        </Typography>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                            {timeMatch?.length > 4 ? (
                              <div className="flex-center font-bold fs14 ml20">
                                + {timeMatch?.length - 4} more
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="flex-row h70AliCen"
                        ref={timeIsBet ? timeRef : null}
                      >
                        <div className="w10Per">
                          <div className="flex-center">
                            <Typography
                              className={`font-bold fs12 ${
                                timeIsBet ? "red-color" : "gray6"
                              }`}
                            >
                              {tm}
                            </Typography>
                          </div>
                        </div>
                        <div className="w1Per">
                          <div className="flex-center">
                            <div className="box-ver-split" />
                          </div>
                        </div>
                        {dateList?.length > 0 &&
                          dateList?.map((dt, i) => {
                            const timeMatch =
                              reqApnts?.[`${tm}-${tm?.split(":")?.[0]}:59`];
                            const reqList = timeMatch?.filter(
                              (rl) => rl?.appoinmentDate === dt
                            );

                            let aptType = "Others";
                            if (reqList?.length > 0) {
                              const sur = reqList?.find(
                                (dd) => dd?.serviceType === "Surgery"
                              );
                              const emer = reqList?.find(
                                (dd) => dd?.serviceType === "Emergency"
                              );
                              const vacc = reqList?.find(
                                (dd) => dd?.serviceType === "Vaccination"
                              );
                              const consData = reqList?.find(
                                (dd) => dd?.serviceType === "Consultation"
                              );

                              aptType = sur
                                ? "Surgery"
                                : emer
                                ? "Emergency"
                                : vacc
                                ? "Vaccination"
                                : consData
                                ? "Consultaion"
                                : "Others";
                            }

                            return (
                              <>
                                <div className="w20Per ph5 pv2">
                                  {reqList?.length > 0 ? (
                                    <div
                                      className={
                                        aptType === "Surgery"
                                          ? "cal-gray-box"
                                          : aptType === "Vaccination"
                                          ? "cal-green-box"
                                          : aptType === "Consultaion"
                                          ? "cal-blue-box"
                                          : aptType === "Emergency"
                                          ? "cal-red-box"
                                          : "cal-rose-box"
                                      }
                                    >
                                      <div className="flex-column jus-con-spa-bet h50">
                                        <div className="flex-row">
                                          {reqList?.length > 0
                                            ? reqList
                                                ?.slice(0, 2)
                                                ?.map((st, si) => (
                                                  <Typography
                                                    className={`${
                                                      si !== 0 ? "ml2" : ""
                                                    } font-bold fs8 cal-time ${
                                                      aptType === "Vaccination"
                                                        ? "green-back"
                                                        : aptType ===
                                                          "Consultaion"
                                                        ? "card-bot-blue-back"
                                                        : aptType ===
                                                          "Emergency"
                                                        ? "card-bot-red-back"
                                                        : "card-bot-rose-back"
                                                    }`}
                                                  >
                                                    {st?.appoimentTime}
                                                  </Typography>
                                                ))
                                            : null}
                                        </div>
                                        {reqList?.length > 2 ? (
                                          <Typography className="font-bold fs10 card-black">
                                            + {reqList?.length - 2} more
                                          </Typography>
                                        ) : (
                                          <div />
                                        )}
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                                <div
                                  className="w1Per"
                                  key={i + dt?.name + "verbar"}
                                >
                                  <div className="flex-center">
                                    <div className="box-ver-split" />
                                  </div>
                                </div>
                              </>
                            );
                          })}
                      </div>
                    )}
                    <div className="box-hor-split" />
                  </div>
                );
              })}
          </div>
        </Grid>
      </Grid>
      <VetBookAppointment
        modalVisible={modalBookVisible}
        setModalBookVisible={setModalBookVisible}
        selectVet={vetDetails}
        // selectVet={{ doctorId: profile?.id }}
        selectedDate={selectedDate}
      />
    </VetAndUpcomingAppointments>
  );
};

export default VetCalendar;
