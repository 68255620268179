import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../actions/api";
import { hideLoader, showLoader } from "./loaderSlice";

const user = localStorage.getItem("user");
const profile = JSON.parse(user);

export const updateCallPending = createAsyncThunk(
  "updateCallPending",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(showLoader());
      const res = await api({ contentType: true, auth: true }).patch(
        `pendingcalls/${data?.callId}`,
        data?.reqObj
      );
      if (res.status === 200) {
        thunkAPI.dispatch(hideLoader());
        return true;
      }
      if (res.status !== 200) {
        thunkAPI.dispatch(hideLoader());
        return false;
      }
    } catch (err) {
      thunkAPI.dispatch(hideLoader());
      return false;
    }
  }
);

export const updateUserFcmToken = createAsyncThunk(
  "updateUserFcmToken",
  async (token, thunkAPI) => {
    try {
      thunkAPI.dispatch(showLoader());
      const res = await api({ contentType: true, auth: true }).patch(
        "users/saveToken",
        { token }
      );
      if (res.status === 200) {
        thunkAPI.dispatch(hideLoader());
        return true;
      }
      if (res.status !== 200) {
        thunkAPI.dispatch(hideLoader());
        return false;
      }
    } catch (err) {
      thunkAPI.dispatch(hideLoader());
      return false;
    }
  }
);

export const getNotificationsByUserId = createAsyncThunk(
  "getNotificationsByUserId",
  async (userId, thunkAPI) => {
    try {
      thunkAPI.dispatch(showLoader());
      const res = await api({ contentType: true, auth: true }).get(
        `/notifications/user/${userId}`
      );
      if (res.status === 200) {
        thunkAPI.dispatch(hideLoader());
        return res?.data?.notifications;
      }
      if (res.status !== 200) {
        thunkAPI.dispatch(hideLoader());
      }
    } catch (err) {
      thunkAPI.dispatch(hideLoader());
      return false;
    }
  }
);

export const mixedSlice = createSlice({
  name: "mixed",
  initialState: {
    notifications: [],
    comObj: {
      home: { selectedTab: "Outpatient", selectedVet: null },
      vetCal: {
        selectedType: "today",
        userId: null,
        selectedDate: new Date(),
      },
      petDetails: { appoimentId: null, petId: null },
      appointments: {
        selectedVet: "all",
        selectedType: "date",
        selectedDate: new Date(),
      },
    },
  },
  reducers: {
    storeHomeScreenDetails: (state, action) => {
      state.comObj.home = action.payload;
    },
    storeVetCalScreenDetails: (state, action) => {
      state.comObj.vetCal = action.payload;
    },
    storePetDetScreenDetails: (state, action) => {
      state.comObj.petDetails = action.payload;
    },
    storeApntmntScreenDetails: (state, action) => {
      state.comObj.appointments = action.payload;
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(updateCallPending.fulfilled, () => {});
    // builder.addCase(updateUserFcmToken.fulfilled, () => {});
    builder.addCase(getNotificationsByUserId.fulfilled, (state, action) => {
      state.notifications = action.payload;
    });
  },
});

export const {
  storeHomeScreenDetails,
  storeVetCalScreenDetails,
  storePetDetScreenDetails,
  storeApntmntScreenDetails,
} = mixedSlice.actions;

export default mixedSlice.reducer;
