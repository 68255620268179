import { styled } from "@mui/material/styles";

const MainWithoutPadding = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  // flexGrow: 1,
  // background: "#fafafa",
  // height: "100vh",
  // width: "100vW",

  flexGrow: 1,
  background: "#fafafa",
  minHeight: "100vh", // Allows content to grow
  width: "100vw",
  overflow: "auto", // Enables scrolling if content overflows
  position: 'fixed',
}));
export default MainWithoutPadding;
