import { ThemeProvider } from "@mui/material/styles";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import Snackbar from "./components/CustomSnackbar/CustomSnackbar";
import Loader from "./components/Loader";
import "./index.css";
import store from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import { default as theme, default as ThemeProvide } from "./theme";
// import "@fontsource/montserrat";
import { Box, Link, Typography, useMediaQuery } from "@mui/material";
import "./assets/fonts/Montserrat-Black.ttf";
import "./assets/fonts/Montserrat-Bold.ttf";
import "./assets/fonts/Montserrat-Italic.ttf";
import "./assets/fonts/Montserrat-Medium.ttf";
import "./assets/fonts/Montserrat-Regular.ttf";
import "./assets/fonts/Montserrat-SemiBold.ttf";

const AppWrapper = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  return isMobile ? (
    <Box className="flex-center h100vh">
      <div className="p20">
        <div className="text-align-center">
          <Typography className="fs18 font-medium">
            This site is not accessible on mobile devices
          </Typography>
        </div>
        <div className="flex-row-center mt30">
          <Link href="https://play.google.com/store/apps/details?id=com.vetinstant.doctor&pcampaignid=web_share">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
              alt="Play Store"
              width="150"
              height="50"
            />
          </Link>
          <div className="ml20">
            <Link href="https://apps.apple.com/in/app/vetinstant-for-vets/id6449208062">
              <img
                src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                alt="App Store"
                width="150"
                height="50"
              />
            </Link>
          </div>
        </div>
      </div>
    </Box>
  ) : (
    <React.Fragment>
      <Loader />
      <App />
      <Snackbar />
    </React.Fragment>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          {/* <Loader /> */}
          <ThemeProvide>
            <AppWrapper />
            {/* <App />
            <Snackbar /> */}
          </ThemeProvide>
        </Provider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.warn = () => {};
  console.error = () => {};
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
