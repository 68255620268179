import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Typography } from "@mui/material";
import { saveAs } from "file-saver";
import { uniq } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import CustomButton from "../../../../components/CustomButton";
import CustomCheckbox from "../../../../components/CustomCheckbox";
import CustomModal from "../../../../components/CustomModal/CustomModal";
import CustomTable from "../../../../components/CustomTable";
import CustomTextField from "../../../../components/CustomTextField";
import CustomSelect from "../../../../components/Select/Select";
import {
  getAllClinicPaymentList,
  getClinicAppointemnts,
  getClinicStocks,
  getClinicStockSales,
  getProductsByClinic,
  getServices,
} from "../../../../redux/reducers/clinicSlice";
import { getClinicPets } from "../../../../redux/reducers/petSlice";
import { AppColors } from "../../../../util/AppColors";
import {
  NeuteredList,
  payReportFilter,
  petAppntTypeList,
  typeList,
} from "../../../../util/dropList";
import {
  appointmentHeaders,
  paymentsHeaders,
  petsHeaders,
  productHeaders,
  serviceHeaders,
  stockHeaders,
} from "./tableHeaders";

const reprtTabs = [
  "Pets",
  "Appointments",
  "Inventory",
  "Stock",
  "Billing",
  "Revenue",
];

const nameExpan = {
  productName: "Product Name",
  tradeName: "Trade Name",
  category: "Category",
  subCategory: "Sub Category",
  tax: "Tax(%)",
  unitSellingPrice: "Unit Selling Price",
  subUnitSellingPrice: "Subunit Selling Price",

  serviceName: "Service Name",
  sellingPrice: "Selling Price",

  name: "Name",
  parentName: "Parent Name",
  breed: "Breed",
  gender: "Gender",
  reproductiveStatus: "Reproductive Status",
  species: "Species",
  age: "Age",

  apntPetName: "Pet Name",

  payPetName: "Pet Name",
  vetName: "Vet Name",
  balanceDue: "Balance Due",
  consultationType: "Consultation Type",
  paymentStatus: "Status",

  supplierName: "Supplier Name",
  quantity: "Quantity",
  price: "Price",
  batchNo: "Batch No",
};

const initFilter = {
  startDate: new Date(),
  endDate: new Date(),
  type: null,
  category: null,
  subCategory: "",

  species: null,
  breed: null,
  age: null,
  reproductiveStatus: null,

  vetName: null,
  consultationType: null,

  petName: null,
  status: null,

  supplierName: null,
};

const DownloadReports = () => {
  const dispatch = useDispatch();
  const [repType, setRepType] = useState(null);
  const [filterValues, setFilterValues] = useState(initFilter);
  const [editColModVsble, setEditColModVsble] = useState(false);
  const [activeTableHeaders, setActiveTableHeaders] = useState([]);
  const [crntTblHeders, setCrntTblHeders] = useState();
  const [prevModVsble, setPrevModVsble] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [breedList, setBreedList] = useState([]);
  const [ageList, setAgeList] = useState([]);
  const [speciesList, setSpeciesList] = useState([]);
  const [vetList, setVetList] = useState([]);
  const [petList, setPetList] = useState([]);
  const [stockCatList, setCatList] = useState([]);
  const [supplierList, setSupplierList] = useState([]);
  const products = useSelector((state) => state?.clinic?.products?.data);
  const services = useSelector((state) => state?.clinic?.services?.data);
  const pets = useSelector((state) => state?.pet?.clinicPets);
  const appointments = useSelector(
    (state) => state?.clinic?.clinicAppointments
  );
  const payments = useSelector((state) => state?.clinic?.paymentList);
  const stockSales = useSelector((state) => state?.clinic?.stockSales);
  const clinicStocks = useSelector((state) => state?.clinic?.clinicStocks);
  const defPaymntUrl = "?filter=all&type=all";

  useEffect(() => {
    if (repType === "Pets") {
      getTableData({
        list: pets?.pets,
        reportType: "Pets",
        species: filterValues?.species,
        breed: filterValues?.breed,
        age: filterValues?.age,
        reproductiveStatus: filterValues?.reproductiveStatus,
      });
    }
    if (repType === "Appointments") {
      getTableData({
        list: appointments,
        reportType: "Appointments",
        vetName: filterValues?.vetName,
        consultationType: filterValues?.consultationType,
        categType: filterValues?.category,
      });
    }
    if (repType === "Inventory") {
      if (filterValues?.type === "Product") {
        if (
          filterValues?.category !== null ||
          filterValues?.subCategory?.length > 0
        ) {
          getTableData({
            list: products,
            reportType: "Inventory",
            typeFilter: "Product",
          });
        } else {
          dispatch(getProductsByClinic()).then((res) => {
            if (res?.payload) {
              const prdcts = res?.payload?.data;
              getTableData({
                list: products,
                reportType: "Inventory",
                typeFilter: "Product",
              });
              setCrntTblHeders(productHeaders);
              setActiveTableHeaders(
                productHeaders?.map((pd, i) => ({
                  name: pd,
                  isSelected: true,
                  i,
                }))
              );
              getCategoryList(prdcts?.length > 0 ? prdcts : []);
            }
          });
        }
      }
      if (filterValues?.type === "Service") {
        if (
          filterValues?.category !== null ||
          filterValues?.subCategory?.length > 0
        ) {
          getTableData({
            list: services,
            reportType: "Inventory",
            typeFilter: "Service",
          });
        } else {
          dispatch(getServices()).then((res) => {
            if (res?.payload) {
              const servics = res?.payload?.data;
              getTableData({
                list: servics,
                reportType: "Inventory",
                typeFilter: "Service",
              });
              setCrntTblHeders(serviceHeaders);
              setActiveTableHeaders(
                serviceHeaders?.map((pd, i) => ({
                  name: pd,
                  isSelected: true,
                  i,
                }))
              );
              getCategoryList(servics?.length > 0 ? servics : []);
            }
          });
        }
      }
    }
    if (repType === "Billing") {
      getTableData({
        list: payments,
        vetName: filterValues?.vetName,
        petName: filterValues?.petName,
        consultationType: filterValues?.consultationType,
        paymentStatus: filterValues?.status,
      });
    }
    if (repType === "Stock") {
      getTableData({
        list: clinicStocks?.data,
        // list: stockSales,
        typeFilter: filterValues?.type,
        categType: filterValues?.category,
        supplierName: filterValues?.supplierName,
      });
    }
  }, [filterValues]);

  const getCategoryList = (list) => {
    const catList = list
      ?.filter((prd) => prd?.category !== null)
      ?.map((prd) => {
        return prd?.category;
      });
    const uniqCatList = uniq(catList);
    if (uniqCatList?.length > 0) {
      const reqList = uniqCatList?.map((cat) => ({ name: cat, value: cat }));
      setCategoryList(reqList);
    } else {
      setCategoryList([]);
    }
  };

  const handleSelRepType = (typ) => {
    setRepType(typ);
    if (typ === "Pets") {
      setFilterValues(initFilter);
      setCrntTblHeders(petsHeaders);
      setActiveTableHeaders(
        petsHeaders?.map((pd, i) => ({ name: pd, isSelected: true, i }))
      );
      dispatch(getClinicPets(`?type=all`)).then((res) => {
        if (res?.payload) {
          const petList = res?.payload?.data;
          const species = [];
          const breeds = [];
          const ages = [];
          getTableData({ list: petList, reportType: "Pets" });
          if (petList?.length > 0) {
            petList?.forEach((pt) => {
              const pet = pt?.listOfDatas;
              species.push(pet?.petType);
              breeds.push(pet?.breed);
              ages.push(moment().diff(pet?.dob, "year") ?? 0);
            });
          }
          setSpeciesList(uniq(species)?.map((sp) => ({ name: sp, value: sp })));
          setBreedList(uniq(breeds)?.map((sp) => ({ name: sp, value: sp })));
          setAgeList(
            uniq(ages)?.map((sp) => ({
              name: sp?.toString(),
              value: sp?.toString(),
            }))
          );
        }
      });
    }
    if (typ === "Appointments") {
      setFilterValues(initFilter);
      setCrntTblHeders(appointmentHeaders);
      setActiveTableHeaders(
        appointmentHeaders?.map((pd, i) => ({ name: pd, isSelected: true, i }))
      );
      dispatch(getClinicAppointemnts()).then((res) => {
        if (res?.payload) {
          const apnts = res?.payload;
          getTableData({
            list: apnts,
            reportType: "Appointments",
          });
          const vetList = apnts
            ?.filter((apnt) => apnt?.doctorName !== "" && apnt?.doctorName)
            ?.map((apnt) => {
              return apnt?.doctorName;
            });
          setVetList(uniq(vetList)?.map((vet) => ({ name: vet, value: vet })));
        }
      });
    }
    if (typ === "Inventory") {
      //to set header and selected type
      setFilterValues({ ...filterValues, type: "Product" });
      setCrntTblHeders(productHeaders);
      setActiveTableHeaders(
        productHeaders?.map((pd, i) => ({ name: pd, isSelected: true, i }))
      );
      // for calling product list api
      dispatch(getProductsByClinic()).then((res) => {
        if (res?.payload) {
          const prducts = res?.payload?.data;
          getTableData({
            list: prducts,
            reportType: "Inventory",
            typeFilter: "Product",
          });
          const catList = prducts
            ?.filter((prd) => prd?.category !== null)
            ?.map((prd) => {
              return prd?.category;
            });
          getCategoryList(res?.payload?.data?.length > 0 ? catList : []);
        }
      });
    }
    if (typ === "Stock") {
      setFilterValues(initFilter);
      setCrntTblHeders(stockHeaders);
      setActiveTableHeaders(
        stockHeaders?.map((pd, i) => ({ name: pd, isSelected: true, i }))
      );
      dispatch(getClinicStocks()).then((res) => {
        // dispatch(getClinicStockSales()).then((res) => {
        if (res?.payload) {
          const stockList = res?.payload?.data;
          getTableData({ list: stockList, reportType: "Stock" });
          const catries = stockList?.map((stc) => {
            return stc?.category;
          });
          setCatList(uniq(catries)?.map((cat) => ({ name: cat, value: cat })));
          const suppliers = stockList?.map((stc) => {
            return stc?.supplierName;
          });
          setSupplierList(
            uniq(suppliers)?.map((sup) => ({ name: sup, value: sup }))
          );
        }
      });
    }
    if (typ === "Billing") {
      setFilterValues(initFilter);
      setCrntTblHeders(paymentsHeaders);
      setActiveTableHeaders(
        paymentsHeaders?.map((pd, i) => ({ name: pd, isSelected: true, i }))
      );
      dispatch(getAllClinicPaymentList(defPaymntUrl)).then((res) => {
        if (res?.payload) {
          const payList = res?.payload;
          getTableData({ list: payList, reportType: "Billing" });
          const vetList = payList
            ?.filter((pay) => pay?.doctorName !== "" && pay?.doctorName)
            ?.map((pay) => {
              return pay?.doctorName;
            });
          setVetList(uniq(vetList)?.map((vet) => ({ name: vet, value: vet })));
          const pets = payList
            ?.filter((pay) => pay?.petName !== "" && pay?.petName)
            ?.map((pay) => {
              return pay?.petName;
            });
          setPetList(uniq(pets)?.map((pet) => ({ name: pet, value: pet })));
        }
      });
    }
  };

  function filterApntList(
    list = [],
    vetName = null,
    consultationType = null,
    categType = null
  ) {
    if (list?.length === 0 || !list) return [];
    if (!vetName && !consultationType && !categType) return list;
    return list?.filter((li) => {
      const matchesVetName = vetName
        ? li?.doctorName?.toLowerCase() === vetName?.toLowerCase()
        : true;
      const matchesConsultationType = consultationType
        ? li?.appoinmentType === consultationType
        : true;
      const matchesCategry = categType
        ? categType === "Inpatient"
          ? li?.inpatient
          : !li?.inpatient
        : true;
      return matchesVetName && matchesConsultationType && matchesCategry;
    });
  }

  function filterPetList(
    list = [],
    species = null,
    breed = null,
    age = null,
    reproductiveStatus = null
  ) {
    if (list?.length === 0 || !list) return [];
    if (!species && !breed && !age && !reproductiveStatus) return list;
    return list?.filter((li) => {
      const pet = li?.listOfDatas;
      const ageVal = moment().diff(pet?.dob, "year");
      const matchesSpecies = species
        ? pet.petType?.toLowerCase() === species?.toLowerCase()
        : true;
      const matchesBreed = breed ? pet.breed === breed : true;
      const matchesAge = age ? ageVal?.toString() === age : true;
      const matchesReprdctStat = reproductiveStatus
        ? pet.reproductiveStatus === reproductiveStatus
        : true;
      return matchesSpecies && matchesBreed && matchesAge && matchesReprdctStat;
    });
  }

  function filterBillingList(
    list = [],
    vetName = null,
    petName = null,
    consultationType = null,
    paymentStatus = null
  ) {
    if (list?.length === 0 || !list) return [];
    if (!vetName && !petName && !consultationType && !paymentStatus) {
      return list;
    }
    return list?.filter((li) => {
      const matchesVetName = vetName
        ? li?.doctorName?.toLowerCase() === vetName?.toLowerCase()
        : true;
      const matchesPetName = petName
        ? li?.petName?.toLowerCase() === petName?.toLowerCase()
        : true;
      const matchesConTyp = consultationType
        ? li?.appointmentType?.toLowerCase() === consultationType?.toLowerCase()
        : true;
      const matchesPayStat = paymentStatus
        ? paymentStatus === "all"
          ? true
          : li?.status?.toLowerCase() === paymentStatus?.toLowerCase()
        : true;
      return (
        matchesVetName && matchesPetName && matchesConTyp && matchesPayStat
      );
    });
  }

  function filterStockList(
    list = [],
    typeFilter = null,
    categType = null,
    supplierName = null
  ) {
    if (list?.length === 0 || !list) return [];
    if (!typeFilter && !categType && !supplierName) return list;
    return list?.filter((li) => {
      const matchesType = typeFilter
        ? li?.type?.toLowerCase() === typeFilter?.toLowerCase()
        : true;
      const matchesCategory = categType
        ? li?.category?.toLowerCase() === categType?.toLowerCase()
        : true;
      const matchesSupName = supplierName
        ? li?.supplierName?.toLowerCase() === supplierName?.toLowerCase()
        : true;
      return matchesType && matchesCategory && matchesSupName;
    });
  }

  function filterCategoryAndSubCategory(
    list = [],
    category = null,
    subCategory = null
  ) {
    if (list?.length === 0 || !list) return [];
    if (!category && subCategory?.length === 0) return list;
    return list?.filter((li) => {
      const matchesCategory = category ? li.category === category : true;
      const matchesSubCategory = subCategory
        ? li?.subCategory?.toLowerCase()?.includes(subCategory?.toLowerCase())
        : true;
      return matchesCategory && matchesSubCategory;
    });
  }

  const getTableData = async ({
    list = [],
    reportType = null,
    typeFilter = null,
    categType = null,
    subCategory = null,
    species = null,
    breed = null,
    age = null,
    reproductiveStatus = null,
    vetName = null,
    consultationType = null,
    petName = null,
    paymentStatus = null,
    supplierName = null,
  }) => {
    if ((reportType ?? repType) === "Pets") {
      const reqPets = await filterPetList(
        list,
        species,
        breed,
        age,
        reproductiveStatus
      );
      const reqList = reqPets?.map((pet, i) => {
        const petDet = pet?.listOfDatas;
        return {
          name: petDet?.petName,
          parentName: petDet?.userName,
          species: petDet?.petType,
          breed: petDet?.breed,
          gender: petDet?.gender,
          reproductiveStatus: petDet?.reproductiveStatus,
          age: petDet?.dob,
        };
      });
      setTableData(reqList);
    }
    if ((reportType ?? repType) === "Appointments") {
      const reqApnts = await filterApntList(
        list,
        vetName,
        consultationType,
        categType
      );
      const reqList = reqApnts?.map((apnt, i) => {
        return {
          apntPetName: apnt?.petName,
          parentName: apnt?.userName,
          vetName: apnt?.doctorName,
          consultationType: apnt?.appoinmentType,
          category: apnt?.inpatient ? "Inpatient" : "Outpatient",
        };
      });
      setTableData(reqList);
    }
    if ((reportType ?? repType) === "Inventory") {
      if ((typeFilter ?? filterValues?.type) === "Product") {
        const reqPrdcts = await filterCategoryAndSubCategory(
          list ?? products,
          categType ?? filterValues?.category,
          subCategory ?? filterValues?.subCategory
        )?.map((prd) => ({
          productName: prd?.name,
          tradeName: prd?.tradeName,
          category: prd?.category,
          subCategory: prd?.subCategory,
          tax: prd?.tax,
          unitSellingPrice: prd?.sellPrice,
          subUnitSellingPrice: prd?.subunitSellingPrice,
        }));
        setTableData(reqPrdcts);
      }
      if ((typeFilter ?? filterValues?.type) === "Service") {
        const reqServices = await filterCategoryAndSubCategory(
          list ?? services,
          categType ?? filterValues?.category,
          subCategory ?? filterValues?.subCategory
        )?.map((ser) => ({ ...ser, sellingPrice: ser?.servicePrice }));
        setTableData(reqServices);
      }
    }
    if ((reportType ?? repType) === "Billing") {
      const reqBills = await filterBillingList(
        list,
        vetName,
        petName,
        consultationType,
        paymentStatus
      );
      const reqList = reqBills?.map((bil, i) => {
        return {
          payPetName: bil?.petName,
          vetName: bil?.doctorName,
          balanceDue:
            Number(bil?.balanceDue) > 0
              ? `Rs ${Number(bil?.balanceDue).toFixed(2)}`
              : "Nil",
          consultationType: bil?.appointmentType,
          paymentStatus: bil?.status === "paid" ? "Paid" : "Unpaid",
        };
      });
      setTableData(reqList);
    }
    if ((reportType ?? repType) === "Stock") {
      const reqStocks = await filterStockList(
        list,
        typeFilter,
        categType,
        supplierName
      );
      const reqList = reqStocks?.map((stc, i) => {
        return {
          name: stc?.productName,
          category: stc?.category,
          supplierName: stc?.supplierName,
          quantity: stc?.quantity,
          batchNo: stc?.batchNo,
          price: stc?.purchaseCost,
        };
      });
      setTableData(reqList);
    }
  };

  const getImgeBasedType = (typ) => {
    return typ === "Pets"
      ? require("../../../../assets/images/svg/pets.svg").default
      : typ === "Appointments"
      ? require("../../../../assets/images/svg/appointments.svg").default
      : typ === "Inventory"
      ? require("../../../../assets/images/svg/inventory.svg").default
      : typ === "Stock"
      ? require("../../../../assets/images/svg/stock.svg").default
      : typ === "Billing"
      ? require("../../../../assets/images/svg/billing.svg").default
      : require("../../../../assets/images/svg/revenue.svg").default;
  };

  const handleChange = (e) => {
    const { name, value } = e?.target;
    const reqObj = { ...filterValues, [name]: value };
    if (name === "type") {
      reqObj.category = null;
      reqObj.subCategory = "";
    }
    if (name === "category") {
      reqObj.subCategory = "";
    }
    setFilterValues(reqObj);
  };

  const handleEditColumn = () => {
    setEditColModVsble(true);
  };

  const onEditModClose = () => {
    setEditColModVsble(false);
  };

  const handleSelcetdColumn = (selected, ind) => {
    const reqList = activeTableHeaders?.map((act, i) => {
      if (i === ind) return { ...act, isSelected: selected };
      return act;
    });
    setActiveTableHeaders(reqList);
  };

  const handleSaveTableColumn = () => {
    const reqList = activeTableHeaders
      ?.filter((act) => act?.isSelected)
      ?.map((act) => {
        return act?.name;
      });
    setCrntTblHeders(reqList);
    setEditColModVsble(false);
  };

  const onClosePrevMod = () => {
    setPrevModVsble(false);
  };

  const handleDownload = () => {
    const reqTableList = tableData?.map((dta) => {
      const obj = {};
      crntTblHeders?.forEach((hd) => {
        const newHeader = nameExpan[hd] || hd; 
        obj[newHeader] = dta?.[hd];
      });
      return obj;
    });

    // Define the sheet data
    const worksheet = XLSX.utils.json_to_sheet(reqTableList);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Write the workbook and trigger a download
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(data, "TableData.xlsx");
  };

  return (
    <>
      <div className="flex-row-between-align-center mt30">
        {reprtTabs?.map((rprt) => (
          <div
            key={rprt}
            className="w15Per flex-column mh5 cursor flex-center"
            onClick={() => handleSelRepType(rprt)}
          >
            <div
              className={`flex-center brdr-rd5-h120 ${
                repType === rprt ? "report-selctd-bodr" : "report-unsel-bodr"
              }`}
            >
              <img alt="" src={getImgeBasedType(rprt)} className="imghw30" />
            </div>
            <Typography className="font-medium fs12 black12 mt5">
              {rprt}
            </Typography>
          </div>
        ))}
      </div>
      {repType ? (
        <div>
          <Typography className="blue-color fs16 font-bold mv10">
            Filters
          </Typography>
          <div className="flex-row">
            <div className="flex-column w15Per">
              <CustomTextField
                label="Start Date"
                name="startDate"
                fullWidth
                handleChange={handleChange}
                value={filterValues?.startDate}
                mobileDate
                labelTop
              />
            </div>
            <div className="flex-column ml15 w15Per">
              <CustomTextField
                label="End Date"
                name="endDate"
                fullWidth
                handleChange={handleChange}
                value={filterValues?.endDate}
                mobileDate
                labelTop
              />
            </div>
            {repType === "Pets" ? (
              <>
                <div className="flex-column ml15 w15Per">
                  <CustomSelect
                    list={speciesList}
                    name="species"
                    value={filterValues?.species}
                    handleChange={handleChange}
                    label="Species"
                    labelTop
                    select
                  />
                </div>
                <div className="flex-column ml15 w15Per">
                  <CustomSelect
                    list={breedList}
                    name="breed"
                    value={filterValues?.breed}
                    handleChange={handleChange}
                    label="Breed"
                    labelTop
                    select
                  />
                </div>
                <div className="flex-column ml15 w15Per">
                  <CustomSelect
                    list={ageList}
                    name="age"
                    value={filterValues?.age}
                    handleChange={handleChange}
                    label="Age"
                    labelTop
                    select
                  />
                </div>
                <div className="flex-column ml15 w15Per">
                  <CustomSelect
                    list={NeuteredList}
                    name="reproductiveStatus"
                    value={filterValues?.reproductiveStatus}
                    handleChange={handleChange}
                    label="Reproductive Status"
                    labelTop
                    select
                  />
                </div>
              </>
            ) : null}
            {repType === "Appointments" || repType === "Billing" ? (
              <div className="flex-column ml15 w15Per">
                <CustomSelect
                  list={vetList}
                  name="vetName"
                  value={filterValues?.vetName}
                  handleChange={handleChange}
                  label="Vet Name"
                  labelTop
                  select
                />
              </div>
            ) : null}
            {repType === "Billing" ? (
              <div className="flex-column ml15 w15Per">
                <CustomSelect
                  list={petList}
                  name="petName"
                  value={filterValues?.petName}
                  handleChange={handleChange}
                  label="Pet Name"
                  labelTop
                  select
                />
              </div>
            ) : null}
            {repType === "Appointments" || repType === "Billing" ? (
              <div className="flex-column ml15 w15Per">
                <CustomSelect
                  list={typeList}
                  name="consultationType"
                  value={filterValues?.consultationType}
                  handleChange={handleChange}
                  label="Consultation Type"
                  labelTop
                  select
                />
              </div>
            ) : null}
            {repType === "Appointments" ? (
              <div className="flex-column ml15 w15Per">
                <CustomSelect
                  list={petAppntTypeList}
                  name="category"
                  value={filterValues?.category}
                  handleChange={handleChange}
                  label="Category"
                  labelTop
                  select
                />
              </div>
            ) : null}
            {repType === "Inventory" ? (
              <>
                <div className="flex-column ml15 w15Per">
                  <CustomSelect
                    list={[
                      { name: "Product", value: "Product" },
                      { name: "Sevice", value: "Service" },
                    ]}
                    name="type"
                    value={filterValues?.type}
                    handleChange={handleChange}
                    label="Type"
                    labelTop
                    select
                  />
                </div>
                <div className="flex-column ml15 w15Per">
                  <CustomSelect
                    list={categoryList}
                    name="category"
                    value={filterValues?.category}
                    handleChange={handleChange}
                    label="Category"
                    labelTop
                    select
                  />
                </div>
                <div className="flex-column ml20 w15Per">
                  <CustomTextField
                    name="subCategory"
                    fullWidth
                    handleChange={handleChange}
                    value={filterValues?.subCategory}
                    labelTop
                    label="Sub Category"
                  />
                </div>
              </>
            ) : null}
            {repType === "Billing" ? (
              <div className="flex-column ml15 w15Per">
                <CustomSelect
                  list={payReportFilter}
                  name="status"
                  value={filterValues?.status}
                  handleChange={handleChange}
                  label="Status"
                  labelTop
                  select
                />
              </div>
            ) : null}
            {repType === "Stock" ? (
              <>
                <div className="flex-column ml15 w15Per">
                  <CustomSelect
                    list={[
                      { name: "Product", value: "Product" },
                      { name: "Sevice", value: "Service" },
                    ]}
                    name="type"
                    value={filterValues?.type}
                    handleChange={handleChange}
                    label="Type"
                    labelTop
                    select
                  />
                </div>
                <div className="flex-column ml15 w15Per">
                  <CustomSelect
                    list={stockCatList}
                    name="category"
                    value={filterValues?.category}
                    handleChange={handleChange}
                    label="Category"
                    labelTop
                    select
                  />
                </div>
                <div className="flex-column ml20 w15Per">
                  <CustomSelect
                    list={supplierList}
                    name="supplierName"
                    value={filterValues?.supplierName}
                    handleChange={handleChange}
                    label="Supplier Name"
                    labelTop
                    select
                  />
                </div>
              </>
            ) : null}
          </div>

          <div className="flex-row-ali-cen mt5">
            <Typography className="gray15 fs16 font-bold">
              Total {tableData?.length}
            </Typography>
            <div
              className="flex-row-ali-cen ml50 cursor"
              onClick={handleEditColumn}
            >
              <EditOutlinedIcon
                sx={{
                  color: AppColors.appPrimary,
                  width: 20,
                  height: 20,
                }}
              />
              <Typography className="blue-color fs16 font-medium ml5">
                Edit columns ({crntTblHeders?.length})
              </Typography>
            </div>
          </div>
          <div className="m20Min">
            <div className="mt10">
              <CustomTable
                grey
                datas={tableData?.length > 0 ? tableData?.slice(0, 2) : []}
                columns={crntTblHeders}
              />
            </div>
          </div>
          <div className="flex1-end">
            <div>
              <CustomButton
                text="Preview"
                smallBtn
                whiteBtn
                onClick={() => setPrevModVsble(true)}
              />
            </div>
            <div className="ml10">
              <CustomButton text="Download" smallBtn onClick={handleDownload} />
            </div>
          </div>
        </div>
      ) : null}
      <CustomModal
        open={editColModVsble}
        onClose={onEditModClose}
        header="Columns"
        modal
        modalWidth={20}
      >
        <div className="ph20">
          {activeTableHeaders?.map((hr) => (
            <div className="flex-row" key={hr?.i}>
              <CustomCheckbox
                label={nameExpan?.[hr?.name]}
                checked={hr?.isSelected}
                onChange={() => handleSelcetdColumn(!hr?.isSelected, hr?.i)}
              />
            </div>
          ))}
          <div className="flex-end mb10">
            <div>
              <CustomButton
                text="Save"
                smallBtn
                onClick={handleSaveTableColumn}
              />
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={prevModVsble}
        onClose={onClosePrevMod}
        header="Preview"
        modal
        modalWidth={95}
      >
        <CustomTable
          grey
          datas={tableData?.length > 0 ? tableData : []}
          columns={crntTblHeders}
        />
      </CustomModal>
    </>
  );
};

export default DownloadReports;
